import SolutionSubmission from "./SolutionSubmission";
import SolutionDashboard from "./SolutionDashboard";
import { CodeRunDetails } from "../../../types/CodeRunDetails";
import { Grid } from "@mui/material";
import { useState } from "react";
import CodeRunReview from "./CodeRunReview";
import CodeRunReviewNotification from "./CodeRunReviewNotification";

interface SolutionSubmissionExtendedProps {
  challengeId: string;
  sourceRunNumber?: string;
  userId: string;
  templateId: string;
  header?: string;
  subHeader?: string;
  isEdited: boolean;
  setIsEdited: (isEdited: boolean) => void;
  code: string;
  setCode: (code: string) => void;
  onSuccessfulCode?: (codeDetailed: CodeRunDetails) => void;
}

const SolutionSubmissionExtended = ({
  challengeId,
  sourceRunNumber,
  userId,
  templateId,
  header = "Submit solution",
  subHeader,
  isEdited,
  setIsEdited,
  code,
  setCode,
  onSuccessfulCode,
}: SolutionSubmissionExtendedProps) => {
  const [runNumber, setRunNumber] = useState<undefined | string>(
    sourceRunNumber
  );
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <CodeRunReviewNotification
            challengeId={challengeId}
            runNumber={runNumber}
            userId={userId}
          />
        </Grid>
        {!!runNumber && (
          <Grid item xs={12}>
            <CodeRunReview
              challengeId={challengeId}
              codeRunNumber={runNumber}
              userId={userId}
            />
          </Grid>
        )}
      </Grid>
      <Grid container>
        <Grid item xs={8}>
          <SolutionSubmission
            challengeId={challengeId}
            sourceRunNumber={sourceRunNumber}
            userId={userId}
            style={{ flex: "7", paddingTop: "0px", paddingBottom: "0px" }}
            onSuccessfulCode={(codeDetailed: CodeRunDetails) => {
              setRunNumber(codeDetailed.challenge_run_number);
              onSuccessfulCode?.(codeDetailed);
            }}
            header={header}
            subHeader={subHeader}
            isEdited={isEdited}
            setIsEdited={setIsEdited}
            code={code}
            setCode={setCode}
          />
        </Grid>
        <Grid item xs={3}>
          <SolutionDashboard
            challengeId={challengeId}
            userId={userId}
            runNumber={runNumber}
            templateId={templateId}
            style={{
              minWidth: "400px",
              paddingTop: "30px", // TEMPORARY. Remove this custom padding after removing go-to-old-view card
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SolutionSubmissionExtended;
