import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Checkbox,
  Snackbar,
  Alert,
  CircularProgress,
  Stack,
} from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import { BackendError } from "../../types/BackendError";
import {
  getCurrentUser,
  getChallengeSubscriptions,
  updateSubscription,
} from "../../pages/ChallengeViewSteps/LocalOperations";
import { ChallengeDetailed } from "../../types/ChallengesDetailed";

interface ChallengeSubscriptionProps {
  challenge: ChallengeDetailed;
}

interface Subscription {
  alert_sent_at: string;
  challenge_id: number;
  created_at: string;
  id: number;
  notification: string;
  user_id: number;
}

const notificationMappings: { [key: string]: string } = {
  "Snapshot not delivered": "not_delivered",
  "Validation failed": "error",
};

const ChallengeAlertSubscription = ({
  challenge,
}: ChallengeSubscriptionProps) => {
  const [selectedNotifications, setSelectedNotifications] = useState<string[]>(
    []
  );
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const queryClient = useQueryClient();

  const updateSubscriptionMutation = useMutation<
    void,
    BackendError,
    { notification: string; active: boolean }
  >(
    (formData) =>
      updateSubscription(formData.notification, formData.active, challenge.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("challengeSubscriptions");
        setIsSuccessSnackbarOpen(true);
        setSuccessMessage("Subscription updated successfully");
        setErrorMessage("");
      },
      onError: (error) => {
        console.error("Failed to update subscription", error);
        setErrorMessage("Failed to update subscription. Please try again");
      },
    }
  );

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const currentUser = await getCurrentUser();
        if (!currentUser) {
          throw new Error("Failed to fetch current user");
        }
        const data: Subscription[] = await getChallengeSubscriptions(
          challenge.id
        );
        const notifications = data.map(
          (subscription) => subscription.notification
        );

        setSelectedNotifications(notifications);
        setIsLoading(false);
      } catch (error) {
        setErrorMessage("Failed to fetch subscriptions");
        setIsLoading(false);
      }
    };

    fetchSubscriptions();
  }, []);

  const handleNotificationSelection = (
    notification: string,
    selected: boolean
  ) => {
    const notificationValue = notificationMappings[notification];
    if (selected) {
      updateSubscriptionMutation.mutate({
        notification: notificationValue,
        active: true,
      });
      setSelectedNotifications((prevNotifications) => [
        ...prevNotifications,
        notificationValue,
      ]);
    } else {
      updateSubscriptionMutation.mutate({
        notification: notificationValue,
        active: false,
      });
      setSelectedNotifications((prevNotifications) =>
        prevNotifications.filter((n) => n !== notificationValue)
      );
    }
  };

  return (
    <>
      <Box sx={{ m: 1 }}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Typography variant="body1" component="div">
              Select which notifications you would like to receive
            </Typography>
            {Object.keys(notificationMappings).map((notification) => (
              <Stack
                key={notification}
                direction="row"
                alignItems="center"
                spacing={1}
              >
                <Checkbox
                  checked={selectedNotifications.includes(
                    notificationMappings[notification]
                  )}
                  onChange={(e) =>
                    handleNotificationSelection(notification, e.target.checked)
                  }
                />
                <Typography>{notification}</Typography>
              </Stack>
            ))}

            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          </>
        )}
      </Box>
      <Box sx={{ m: 1, minWidth: 120 }}>
        <Snackbar
          open={isSuccessSnackbarOpen}
          autoHideDuration={3000}
          onClose={() => setIsSuccessSnackbarOpen(false)}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={() => setIsSuccessSnackbarOpen(false)}
            severity="success"
          >
            {successMessage}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};

export default ChallengeAlertSubscription;
