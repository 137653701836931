import API from "../../API";
import axios from "axios";
import { Project } from "../../types/Projects";
import { axiosTokened } from "../../utils/AxiosInstances";
import ProjectRoleEnum from "../../types/ProjectRoleEnum";

const getProject = async (projectId: string) => {
  const { data } = await axios.get<Project>(API.genGetProject(projectId));
  return data;
};

const getProjectUsers = async (projectId: string) => {
  const { data } = await axios.get(API.genGetProjectUsers(projectId));
  return data;
};

const postAddUserToProject = async ({
  projectId,
  users,
}: {
  projectId: string;
  users: { user_id: number; role: ProjectRoleEnum }[];
}) => {
  await axiosTokened.post(API.genPostAddUserToProject(projectId), {
    users: users,
  });
};

const postUpdateUserProjectRole = async ({
  projectId,
  userId,
  role,
}: {
  projectId: string;
  userId: string;
  role: ProjectRoleEnum;
}) => {
  await axiosTokened.post(
    API.genUpdateUserProjectRole(projectId, userId, role)
  );
};

export { getProject, getProjectUsers, postAddUserToProject, postUpdateUserProjectRole };

