import { useQuery } from "react-query";
import { Counter } from "../../../types/counter";
import { BackendError } from "../../../types/BackendError";
import { User } from "../../../types/Users";
import {
  getAllChallengesCount,
  getFreeChallengesCount,
  getSolverChallengesCount,
  getChallengesWithErrorsCount,
  getChallengesWithWarningsCount,
  getChallengesNeedFixCount,
  getChallengesWaitingForReviewCount,
  getChallengesCreationSLABreachedCount,
  getChallengesDeliverySLABreachedCount,
  getAllProjectChallengesCount,
  getFreeProjectChallengesCount,
  getSolverProjectChallengesCount,
  getProjectChallengesWithErrorsCount,
  getProjectChallengesWithWarningsCount,
  getProjectChallengesNeedFixCount,
  getProjectChallengesWaitingForReviewCount,
  getProjectChallengesCreationSLABreachedCount,
  getProjectChallengesDeliverySLABreachedCount,
} from "../../ChallengeViewSteps/LocalOperations";

interface ChallengeCounts {
  qAllChallengesCount: ReturnType<typeof useQuery<Counter, BackendError>>;
  qFreeChallengesCount: ReturnType<typeof useQuery<Counter, BackendError>>;
  qSolverChallengesCount: ReturnType<typeof useQuery<Counter, BackendError>>;
  qChallengesWithErrorsCount: ReturnType<
    typeof useQuery<Counter, BackendError>
  >;
  qChallengesWithWarningsCount: ReturnType<
    typeof useQuery<Counter, BackendError>
  >;
  qChallengesNeedFixCount: ReturnType<
    typeof useQuery<Counter, BackendError>
  >;
  qChallengesWaitingForReviewCount: ReturnType<
    typeof useQuery<Counter, BackendError>
  >;
  qChallengesCreationSLABreachedCount: ReturnType<
    typeof useQuery<Counter, BackendError>
  >;
  qChallengesDeliverySLABreachedCount: ReturnType<
    typeof useQuery<Counter, BackendError>
  >;
}

export const useChallengesCount = (
  user: User,
  projectId?: string,
  showMySolvings: boolean = false
): ChallengeCounts => {
  const enabled = !!user.id;

  const countOperations = projectId
    ? {
        getAll: () => getAllProjectChallengesCount(projectId),
        getFree: () => getFreeProjectChallengesCount(projectId),
        getSolver: () =>
          getSolverProjectChallengesCount(projectId),
        getErrors: () =>
          getProjectChallengesWithErrorsCount(projectId, showMySolvings),
        getWarnings: () =>
          getProjectChallengesWithWarningsCount(
            projectId,
            showMySolvings
          ),
        getNeedFix: () =>
          getProjectChallengesNeedFixCount(projectId, showMySolvings),
        getWaitingForReview: () =>
          getProjectChallengesWaitingForReviewCount(
            projectId,
            showMySolvings
          ),
        getCreationSLABreach: () =>
          getProjectChallengesCreationSLABreachedCount(
            projectId,
            showMySolvings
          ),
        getDeliverySLABreach: () =>
          getProjectChallengesDeliverySLABreachedCount(
            projectId,
            showMySolvings
          ),
      }
    : {
        getAll: getAllChallengesCount,
        getFree: getFreeChallengesCount,
        getSolver: getSolverChallengesCount,
        getErrors: () => getChallengesWithErrorsCount(showMySolvings),
        getWarnings: () => getChallengesWithWarningsCount(showMySolvings),
        getNeedFix: () => getChallengesNeedFixCount(showMySolvings),
        getWaitingForReview: () =>
          getChallengesWaitingForReviewCount(showMySolvings),
        getCreationSLABreach: () =>
          getChallengesCreationSLABreachedCount(showMySolvings),
        getDeliverySLABreach: () =>
          getChallengesDeliverySLABreachedCount(showMySolvings),
      };

  const qAllChallengesCount = useQuery<Counter, BackendError>(
    ["allChallengesCount", user.id, projectId],
    countOperations.getAll,
    { enabled }
  );

  const qFreeChallengesCount = useQuery<Counter, BackendError>(
    ["freeChallengesCount", user.id, projectId],
    countOperations.getFree,
    { enabled }
  );

  const qSolverChallengesCount = useQuery<Counter, BackendError>(
    ["solverChallengesCount", user.id, projectId],
    countOperations.getSolver,
    { enabled }
  );

  const qChallengesWithErrorsCount = useQuery<Counter, BackendError>(
    ["challengesWithErrorsCount", user.id, projectId, showMySolvings],
    countOperations.getErrors,
    { enabled }
  );

  const qChallengesWithWarningsCount = useQuery<Counter, BackendError>(
    ["challengesWithWarningsCount", user.id, projectId, showMySolvings],
    countOperations.getWarnings,
    { enabled }
  );

  const qChallengesNeedFixCount = useQuery<Counter, BackendError>(
    ["challengesNeedFixCount", user.id, projectId, showMySolvings],
    countOperations.getNeedFix,
    { enabled }
  );

  const qChallengesWaitingForReviewCount = useQuery<
    Counter,
    BackendError
  >(
    [
      "challengesWaitingForReviewCount",
      user.id,
      projectId,
      showMySolvings,
    ],
    countOperations.getWaitingForReview,
    { enabled }
  );

  const qChallengesCreationSLABreachedCount = useQuery<
    Counter,
    BackendError
  >(
    [
      "challengesCreationSLABreachedCount",
      user.id,
      projectId,
      showMySolvings,
    ],
    countOperations.getCreationSLABreach,
    { enabled }
  );

  const qChallengesDeliverySLABreachedCount = useQuery<
    Counter,
    BackendError
  >(
    [
      "challengesDeliverySLABreachedCount",
      user.id,
      projectId,
      showMySolvings,
    ],
    countOperations.getDeliverySLABreach,
    { enabled }
  );

  return {
    qAllChallengesCount,
    qFreeChallengesCount,
    qSolverChallengesCount,
    qChallengesWithErrorsCount,
    qChallengesWithWarningsCount,
    qChallengesNeedFixCount,
    qChallengesWaitingForReviewCount,
    qChallengesCreationSLABreachedCount,
    qChallengesDeliverySLABreachedCount,
  };
};
