import React, { useState, useEffect, useRef } from "react";
import { Box, Alert, Paper, Typography } from "@mui/material";
import {
  Link,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import SelectRole from "./AccountNewSteps/SelectRole";
import DefineAccountDetails from "./AccountNewSteps/DefineAccountDetails";
import { useMutation } from "react-query";
import { BackendError } from "../types/BackendError";
import { createUser } from "./LoginSteps/LocalOperations";
import NewAccountDetails from "../types/NewAccountDetails";
import AccountCreationTab from "../types/AccountCreationTabsEnum";
import PlatformRoles from "../types/PlatformRoles";

enum LocalState {
  selectRole,
  defineAccountDetails,
}

const AccountNew = () => {
  const [step, setStep] = useState<LocalState>(LocalState.selectRole);
  const [selectedRole, setSelectedRole] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserPassword, setNewUserPassword] = useState("");
  const [isAgreed, setAgreed] = useState<boolean>(false);
  const [isCreatingUser, setIsCreatingUser] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const accountNewRef = useRef<NewAccountDetails | null>(null);

  const getAccountDetails = (): NewAccountDetails => {
    if (accountNewRef.current !== null) {
      return accountNewRef.current;
    }
    accountNewRef.current = {
      email: "",
      unhashed_pass: "",
      role_name: "",
    };
    return accountNewRef.current;
  };

  const postAccount = useMutation<void, BackendError, NewAccountDetails>(
    createUser,
    {
      onMutate: () => setIsCreatingUser(true),
      onError: () => {
        setIsCreatingUser(false);
        setErrorMessage("Failed to create account. Please try again.");
      },
      onSuccess: () => {
        setIsCreatingUser(false);
        setErrorMessage("");
        navigate("/", {
          state: { openAccountCreateSnackbar: true },
          replace: true,
        });
      },
    }
  );

  useEffect(() => {
    const tab = searchParams.get("tab") as AccountCreationTab;

    if (tab) {
      switch (tab) {
        case AccountCreationTab.DefineAccountDetails:
          setStep(LocalState.defineAccountDetails);
          break;
        default:
          setStep(LocalState.selectRole);
          break;
      }
    } else {
      navigate(
        { search: `?tab=${AccountCreationTab.SelectRole}` },
        { replace: true }
      );
    }

    if (!selectedRole) {
      setStep(LocalState.selectRole);
      window.history.replaceState(null, "", `?tab=${AccountCreationTab.SelectRole}`);
    }
  }, [searchParams, selectedRole, navigate, setSearchParams]);

  const navigateToStep = (step: string) => {
    navigate(`${location.pathname}?tab=${step}`);
  };

  const onRoleSelectSuccess = (selectedRole: string) => {
    getAccountDetails().role_name = selectedRole;
    navigateToStep(AccountCreationTab.DefineAccountDetails);
  };

  const onAccountCreationSuccess = (email: string, password: string) => {
    getAccountDetails().email = email;
    getAccountDetails().unhashed_pass = password;
    setNewUserEmail(email);
    setNewUserPassword(password);
    postAccount.mutate(getAccountDetails());
  };

  const renderRoleMessage = () => {
    if (selectedRole === PlatformRoles.BUSINESS_PARTNER) {
      return (
        <Typography variant="body2" gutterBottom align="right">
          Looking for work?{" "}
          <Link
            to={`/users/new/?tab=${AccountCreationTab.SelectRole}`}
            style={{ textDecoration: "none" }}
          >
            <Typography variant="body2" color="primary" component="span">
              Apply as contributor
            </Typography>
          </Link>
        </Typography>
      );
    } else if (selectedRole === PlatformRoles.CONTRIBUTOR) {
      return (
        <Typography variant="body2" gutterBottom>
          Looking for data?{" "}
          <Link
            to={`/users/new/?tab=${AccountCreationTab.SelectRole}`}
            style={{ textDecoration: "none" }}
          >
            <Typography variant="body2" color="primary" component="span">
              Apply as business
            </Typography>
          </Link>
        </Typography>
      );
    }
    return null;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        maxWidth={1200}
        width="100%"
        display="flex"
        justifyContent={"end"}
        marginBottom={2}
      >
        {step === LocalState.defineAccountDetails ? (
          <>{renderRoleMessage()}</>
        ) : 
        (
          <Typography
            variant="body2"
            sx={{ visibility: "hidden" }}
            gutterBottom
          >
            Hidden
          </Typography>
        )}
      </Box>
      <Paper
        elevation={0}
        sx={{
          maxWidth: 1200,
          width: "100%",
        }}
      >
        {step === LocalState.selectRole && (
          <SelectRole
            onRoleSelectSuccess={onRoleSelectSuccess}
            selectedRole={selectedRole}
            setSelectedRole={setSelectedRole}
          />
        )}
        {step === LocalState.defineAccountDetails && (
          <DefineAccountDetails
            onAccountCreationSuccess={onAccountCreationSuccess}
            email={newUserEmail}
            setUserEmail={setNewUserEmail}
            password={newUserPassword}
            setUserPassword={setNewUserPassword}
            didAgree={isAgreed}
            setAgreed={setAgreed}
            isCreatingUser={isCreatingUser}
          />
        )}
        {errorMessage && (
          <Alert severity="error" sx={{ marginTop: 1 }}>
            {errorMessage}
          </Alert>
        )}
        <Typography variant="body1" align="center" paddingBottom={3}>
          Already have an account?{" "}
          <Link to="/login" style={{ textDecoration: "none" }}>
            <Typography variant="body1" color="primary" component="span">
              Log in
            </Typography>
          </Link>
        </Typography>
      </Paper>
    </Box>
  );
};

export default AccountNew;
