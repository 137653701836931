import Editor from "@monaco-editor/react";
import { CircularProgress, Typography } from "@mui/material";
import ErrorAlert from "../../../components/ErrorAlert";
import TitledCodeTextCard from "../../../components/TitledCodeTextCard";
import TitledTruncatableCard from "../../../components/TitledTruncatableCard";
import { CodeRunHead } from "../../../types/CodeRunHead";
import { useQueryCodeRunDetails } from "../../../hooks/UseQueryCodeRunDetails";

interface CodeRunBodyCardsProps {
  codeRunHead: CodeRunHead;
}

const CodeRunBodyCards = ({ codeRunHead }: CodeRunBodyCardsProps) => {
  const {
    challenge_id: challengeId,
    challenge_run_number: runNumber,
    user_id: userId,
    runtime,
  } = codeRunHead;

  const qCodeRun = useQueryCodeRunDetails(challengeId, runNumber, userId);

  if (qCodeRun.isLoading) {
    return <CircularProgress />;
  }
  if (qCodeRun.isError) {
    return <ErrorAlert value={qCodeRun.error} />;
  }
  if (qCodeRun.data === undefined) {
    return (
      <ErrorAlert value="Unexpected error. No body for code run retrieved." />
    );
  }

  const {
    code,
    output,
    stderr,
    stdout,
    internal_error: internalError,
  } = qCodeRun.data;

  return (
    <>
      <Typography variant="h6">Code</Typography>
      <Editor
        language={runtime}
        value={code}
        height="400px"
        options={{ readOnly: true }}
        className="code-editor"
      />
    </>
  );
};

export default CodeRunBodyCards;
