import {
  Select,
  MenuItem,
  SelectChangeEvent,
  SelectProps,
} from "@mui/material";
import { User } from "../types/Users";
import createDefaultUser from "../utils/CreateDefaultUser";
import ErrorAlert from "./ErrorAlert";
import { useQueryUsersBasic } from "../hooks/UseQueryUsersBasic";

interface SelectUserProps {
  userId: string;
  onChangeUser: (user?: User) => void;
  nameForEmpty?: string;
  prependDefaultUser?: boolean;
  labelId?: string;
  size?: SelectProps["size"];
}

const SelectUser = ({
  userId,
  onChangeUser,
  nameForEmpty,
  prependDefaultUser,
  labelId,
  size,
}: SelectUserProps) => {
  const query = useQueryUsersBasic();

  const defaultUser = createDefaultUser();
  const defaultUserMenuItem = (
    <MenuItem value={defaultUser.id} key={defaultUser.id}>
      Invalid user (or unlogged)
    </MenuItem>
  );

  const onChange = (e: SelectChangeEvent<string>): void => {
    if (query.isLoading) {
      return;
    }
    if (!query.data || query.isError) {
      if (nameForEmpty) {
        return onChangeUser(undefined);
      }
      if (prependDefaultUser) {
        return onChangeUser(defaultUser);
      }
      return;
    }
    const getIsMatchById = (user: User) => {
      return user.id === e.target.value;
    };

    if (nameForEmpty && e.target.value === "") {
      return onChangeUser(undefined);
    }

    const userMatch: User | undefined = query.data.find(getIsMatchById);
    const chosenUser =
      userMatch ?? (prependDefaultUser ? defaultUser : undefined);
    onChangeUser(chosenUser);
  };

  const prependedItems = [];
  if (nameForEmpty) {
    prependedItems.push(
      <MenuItem value="">
        <em>{nameForEmpty}</em>
      </MenuItem>
    );
  }
  if (prependDefaultUser) {
    prependedItems.push(defaultUserMenuItem);
  }

  return (
    <>
      <Select
        value={query.isLoading ? "loading" : userId}
        onChange={onChange}
        disabled={query.isLoading}
        labelId={labelId}
        size={size}
        placeholder={nameForEmpty}
      >
        {query.isLoading && (
          <MenuItem disabled value="loading">
            Loading ...
          </MenuItem>
        )}
        {query.isSuccess && [
          prependedItems,
          ...query.data.map((user: User) => (
            <MenuItem value={user.id} key={user.id}>
              {user.email}
            </MenuItem>
          )),
        ]}
        {query.isError && prependDefaultUser && defaultUserMenuItem}
      </Select>
      {query.isError && (
        <ErrorAlert prefix="Could not fetch users. " value={query.error} />
      )}
    </>
  );
};

export default SelectUser;
