import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import BooleanAnswer from "../../../types/BooleanAnswer";
import { BackendError } from "../../../types/BackendError";
import { useUserState } from "../../../hooks/UserStateProvider";
import {
  getIsPassTemporary,
  postTemporaryPassChange,
} from "../LocalOperations";
import {
  Alert,
  Button,
  CircularProgress,
  Container,
  Typography,
  Paper,
} from "@mui/material";
import PageTitle from "../../../components/PageTitle";
import PasswordInput from "./PasswordInput";
import ErrorAlert from "../../../components/ErrorAlert";

const PasswordChange = () => {
  const [isShowSuccess, setIsShowSuccess] = useState(false);
  const [user] = useUserState();
  const [newPassword, setNewPassword] = useState("");
  const queryClient = useQueryClient();
  const qIsPassTemporary = useQuery<BooleanAnswer, BackendError>(
    ["isPassTemp", user.id],
    getIsPassTemporary,
    {
      staleTime: 5 * 60 * 1000, // 5 min
    }
  );
  const qChangeTempPass = useMutation<void, BackendError, string>(
    postTemporaryPassChange,
    {
      onSuccess: () => {
        queryClient.refetchQueries(["isPassTemp", user.id], {
          // outdated data after pass-change success
          active: true,
          exact: true,
        });
        setIsShowSuccess(true);
      },
      retry: false,
    }
  );

  if (isShowSuccess) {
    return (
      <Alert severity="success" onClick={() => setIsShowSuccess(false)}>
        Password Changed Successfully
      </Alert>
    );
  }

  if (
    qIsPassTemporary.isLoading ||
    !qIsPassTemporary.isSuccess ||
    qIsPassTemporary.data.answer === false // password is not temporary, no need to display
  ) {
    return <></>; // silently loading or crashing or not temporary-pass
  }

  return (
    <Container
      className="containerMain"
      style={{
        width: "100%",
        maxWidth: "700px",
        border: "3px solid whitesmoke",
      }}
    >
      <Paper elevation={0}>
        <PageTitle header="Password change" />
        <Typography>
          Since your password was generated or predefined, it is advisable to
          change password into safe one.
        </Typography>
        <div className="br" />
        <div className="gapVerticalMini loose-column">
          <PasswordInput
            placeholder="New Password"
            className="text-field-medium"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <Button
            className="button-medium"
            variant="contained"
            endIcon={
              qChangeTempPass.isLoading ? (
                <CircularProgress size="25px" />
              ) : undefined
            }
            disabled={newPassword.length < 1 || qChangeTempPass.isLoading}
            onClick={() => qChangeTempPass.mutate(newPassword)}
          >
            Change Password
          </Button>
          {qChangeTempPass.isError && !qChangeTempPass.isLoading && (
            <ErrorAlert value={qChangeTempPass.error} />
          )}
        </div>
      </Paper>
    </Container>
  );
};

export default PasswordChange;
