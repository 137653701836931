import React from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Grid,
  Divider,
  CircularProgress,
  Alert,
  Snackbar,
  Paper,
} from "@mui/material";
import PasswordInput from "../LoginSteps/components/PasswordInput";

interface AccountTabProps {
  currentUser: any;
  isEditingName: boolean;
  newName: string;
  isEditingPassword: boolean;
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
  handleNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handlePasswordChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleNewPasswordChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleConfirmPasswordChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleNameEditClick: () => void;
  handleNameSaveClick: () => void;
  handleNameCancelClick: () => void;
  handlePasswordEditClick: () => void;
  handlePasswordSaveClick: () => void;
  handlePasswordCancelClick: () => void;
  isLoading: boolean;
  errorMessage: string;
  isSuccessSnackbarOpen: boolean;
  successMessage: string;
  closeSnackbar: () => void;
  isError: boolean;
}

const AccountTab: React.FC<AccountTabProps> = ({
  currentUser,
  isEditingName,
  newName,
  isEditingPassword,
  currentPassword,
  newPassword,
  confirmPassword,
  handleNameChange,
  handlePasswordChange,
  handleNewPasswordChange,
  handleConfirmPasswordChange,
  handleNameEditClick,
  handleNameSaveClick,
  handleNameCancelClick,
  handlePasswordEditClick,
  handlePasswordSaveClick,
  handlePasswordCancelClick,
  isLoading,
  errorMessage,
  isSuccessSnackbarOpen,
  successMessage,
  closeSnackbar,
  isError,
}) => {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {isLoading ? (
        <CircularProgress />
      ) : isError ? (
        <Alert severity="error">Failed to fetch user data</Alert>
      ) : (
        <Paper
          elevation={0}
          sx={{ padding: 2, marginBottom: 3, border: "none" }}
        >
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={4}>
              <Typography variant="body1" color="textPrimary" marginBottom={1}>
                Your display name
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" color="textPrimary" marginBottom={1}>
                Your email address
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="center" marginBottom={3} spacing={2}>
            <Grid item xs={4} marginBottom={1}>
              {isEditingName ? (
                <TextField
                  id="outlined-helperText"
                  label="Display name"
                  variant="outlined"
                  value={newName}
                  onChange={handleNameChange}
                  fullWidth
                  InputProps={{ readOnly: !isEditingName }}
                />
              ) : (
                <Typography variant="body2">
                  {currentUser && currentUser.shown_name}
                </Typography>
              )}
            </Grid>
            <Grid item md={4}>
              <Typography variant="body2" marginBottom={2}>
                {currentUser && currentUser.email} (not editable)
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {isEditingName ? (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  {errorMessage && (
                    <Alert severity="error" sx={{ marginBottom: 1 }}>
                      {errorMessage}
                    </Alert>
                  )}
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Button variant="outlined" onClick={handleNameCancelClick}>
                      Cancel
                    </Button>
                    <Button variant="contained" onClick={handleNameSaveClick}>
                      Save
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Button variant="outlined" onClick={handleNameEditClick}>
                  Update name
                </Button>
              )}
            </Grid>
          </Grid>
          <Divider/>
          <Typography variant="body1" color="textPrimary" marginTop={3} marginBottom={1}>
            Active logins
          </Typography>
          {isEditingPassword && (
            <Box>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={4} marginBottom={1}>
                  <PasswordInput
                    placeholder="Current password"
                    value={currentPassword}
                    onChange={handlePasswordChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={4} marginBottom={1}>
                  <PasswordInput
                    placeholder="New password"
                    value={newPassword}
                    onChange={handleNewPasswordChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4} marginBottom={1}>
                  <PasswordInput
                    placeholder="Confirm password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>
          )}
          <Grid item xs={6}>
            {isEditingPassword ? (
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                {errorMessage && (
                  <Alert severity="error" sx={{ marginBottom: 1 }}>
                    {errorMessage}
                  </Alert>
                )}
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Button
                    variant="outlined"
                    onClick={handlePasswordCancelClick}
                  >
                    Cancel
                  </Button>
                  <Button variant="contained" onClick={handlePasswordSaveClick}>
                    Save
                  </Button>
                </Box>
              </Box>
            ) : (
              <Button variant="outlined" onClick={handlePasswordEditClick}>
                Update password
              </Button>
            )}
          </Grid>
        </Paper>
      )}
      <Box sx={{ m: 1, minWidth: 120 }}>
        <Snackbar
          open={isSuccessSnackbarOpen}
          autoHideDuration={3000}
          onClose={closeSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={closeSnackbar} severity="success">
            {successMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default AccountTab;
