import {
  CircularProgress,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import DoneIcon from "@mui/icons-material/Done";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import ErrorIcon from "@mui/icons-material/Error";
import ErrorAlert from "../../../components/ErrorAlert";
import Editor from "@monaco-editor/react";

interface EditableFieldProps {
  label: string;
  fieldValue: string;
  onFieldChange: (arg: string) => void;
  onSave?: () => void;
  isLoading: boolean;
  isEditSuccess?: boolean;
  error?: any;
  language?: string;
  multiline?: boolean;
  required?: boolean;
  disabled?: boolean;
  justify?:
    | "flex-start"
    | "center"
    | "flex-end"
    | "space-between"
    | "space-around"
    | "space-evenly"
    | undefined;
}

const EditableField = ({
  label,
  fieldValue,
  onFieldChange,
  onSave,
  isLoading,
  isEditSuccess,
  error,
  language,
  multiline,
  required,
  disabled,
  justify = "center",
}: EditableFieldProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [height, setHeight] = useState(200);

  const isFieldDisabled = isLoading || disabled;

  const onChange = (value?: string) => {
    onFieldChange(value ?? "");
    setIsEditing(true);
  };

  const editor = language ? (
    <>
      <div style={{ width: "100%" }}>
        <Typography variant="overline">{label}</Typography>
        <Editor
          language={language}
          value={fieldValue}
          options={{ readOnly: isFieldDisabled }}
          height={`${height}px`}
          onChange={onChange}
          className="code-editor"
        />
      </div>
      <IconButton
        size="large"
        disabled={height <= 100}
        onClick={() => {
          setHeight((prev: number) => Math.max(prev - 100, 100));
        }}
      >
        <UnfoldLessIcon />
      </IconButton>
      <IconButton
        size="large"
        onClick={() => {
          setHeight((prev: number) => prev + 500);
        }}
      >
        <UnfoldMoreIcon />
      </IconButton>
    </>
  ) : (
    <TextField
      required={required}
      id={label}
      label={label}
      disabled={isFieldDisabled}
      value={fieldValue}
      onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
      multiline={multiline}
      fullWidth={multiline}
    />
  );

  return (
    <>
      <Stack margin="auto" direction="row" justifyContent={justify}>
        {editor}
        {!!onSave && (
          <IconButton
            size="large"
            disabled={isFieldDisabled}
            onClick={() => {
              onSave?.(); // TS complains on `props.onSave()`
              setIsEditing(false);
            }}
          >
            <SaveIcon />
          </IconButton>
        )}

        {isLoading && <CircularProgress />}
        {isEditSuccess && !isEditing && (
          <DoneIcon style={{ marginBlock: "auto" }} color="primary" />
        )}
        {!!error && <ErrorIcon style={{ marginBlock: "auto" }} color="error" />}
      </Stack>
      {!!error && (
        <ErrorAlert prefix="Error occurred while saving: " value={error} />
      )}
    </>
  );
};

export default EditableField;
