import { Alert } from "@mui/material";
import { useUserState } from "../../../hooks/UserStateProvider";
import { useQuerySolving } from "../../ChallengeViewSteps/hooks/UseQuerySolving";
import { Link } from "react-router-dom";
import TTabID from "../../../types/ChallengeViewTabsEnum";

interface CodeRunReviewNotificationProps {
  challengeId: string;
  runNumber?: string;
  userId: string;
  forceShow?: boolean;
}

const CodeRunReviewNotification = ({
  challengeId,
  runNumber,
  userId,
  forceShow = false,
}: CodeRunReviewNotificationProps) => {
  const [user] = useUserState();
  const _isContributor = user.id === userId;
  const qSolving = useQuerySolving(challengeId, userId, {
    enabled: _isContributor,
  });

  const solving = qSolving.data;
  if (!solving) {
    // bad perms, loading, not solving, etc.
    return null;
  }

  if (!forceShow) {
    if (
      !solving.is_reviewed || // still reviewing, so dont notificate
      String(runNumber) === String(solving.last_manual_run_number) || // already looking at reviewed code
      solving.last_manual_run_number === undefined || // submitting first code
      !_isContributor // moderators care about new code, not reviewed code
    ) {
      return null;
    }
  }

  return (
    <Alert severity="info">
      Your last code got reviewed. You may{" "}
      <Link
        to={`/challenges/${challengeId}/?tab=${TTabID.SubmitCode}&runNumber=${solving.last_manual_run_number}&userId=${userId}`}
        reloadDocument
      >
        take a look.
      </Link>
    </Alert>
  );
};

export default CodeRunReviewNotification;
