import { useCallback, useEffect, useMemo, useState } from "react";
import { AttributeUnpacked } from "../../../types/SnapshotMinusTemplate";
import {
  DataTypeBackend,
  DataTypeToBackendArg,
} from "../../../types/EnumDataType";
import {
  Constraints,
  DataLabelsDetailed,
} from "../../../types/DataLabelsDetailed";
import { useQueryClient } from "react-query";
import { genSnapshotTemplatingUnpackedKey } from "../components/SnapshotTemplatingDialog";
import { genSnapshotTemplatingPackedKey } from "../components/SnapshotTemplatingCard";
import { genChallengeDetailsKey } from "../../../hooks/UseQueryChallengeDetails";
import { useInvalidateSchemaLossy } from "./UseQuerySchemaLossy";

type ChangeableAttribute = AttributeUnpacked & {
  onOptionalChange: (newValue: boolean) => void;
  onNullableChange: (newValue: boolean) => void;
  onDataTypeChange: (newValue: DataTypeBackend) => void;
  onConstraintsChange: (newValue: Constraints) => void;
};

const getMatch = (labels: AttributeUnpacked[], labelName: string) => {
  const matches = labels.filter((label) => label.name === labelName);
  if (matches.length === 0) {
    return null;
  }
  return matches[0];
};

const countEntities = (labels: AttributeUnpacked[]) => {
  if (labels.length === 0) {
    return 0;
  }
  return (
    labels[0].counter_null + labels[0].counter_val + labels[0].counter_undef
  );
};

const newLabel = (
  labelName: string,
  entityCount: number
): AttributeUnpacked => {
  return {
    name: labelName,
    is_optional: false,
    is_nullable: false,
    is_extra: false,
    is_missing: true, // snapshot does not have the custom label
    is_conflicting_null: false,
    is_unused_null: false,
    is_conflicting_optional: false,
    is_unused_optional: false,
    counter_val: 0,
    counter_null: 0,
    counter_undef: entityCount,
    type_in_schema: DataTypeToBackendArg.STR,
    type_in_snapshot: null,
  };
};

const expandLabel = (
  label: AttributeUnpacked,
  editor: (editedLabel: ChangeableAttribute) => void
): ChangeableAttribute => {
  const labelPlus: ChangeableAttribute = {
    ...label,
    onOptionalChange: (_v: boolean) => {},
    // cant do `editor({...label, is_optional: newValue})` here
    // because label is of type AttributeUnpacked and not ChangeableAttribute
    onNullableChange: (_v: boolean) => {},
    onDataTypeChange: (_v: DataTypeBackend) => {},
    onConstraintsChange: (_v: Constraints) => {},
  };
  labelPlus.onOptionalChange = (newValue: boolean) => {
    editor({ ...labelPlus, is_optional: newValue });
  };
  labelPlus.onNullableChange = (newValue: boolean) => {
    editor({ ...labelPlus, is_nullable: newValue });
  };
  labelPlus.onDataTypeChange = (newValue: DataTypeBackend) => {
    editor({ ...labelPlus, type_in_schema: newValue });
  };
  labelPlus.onConstraintsChange = (newValue: Constraints) => {
    editor({
      ...labelPlus,
      constraints:
        !newValue || Object.keys(newValue).length === 0 ? undefined : newValue,
    });
  };
  return labelPlus;
};

const _toDataLabels = (labels: ChangeableAttribute[]): DataLabelsDetailed => {
  return labels.map((label) => ({
    label: label.name,
    data_type:
      label.type_in_schema ||
      label.type_in_snapshot ||
      DataTypeToBackendArg.STR,
    optional: label.is_optional,
    nullable: label.is_nullable,
    constraints: label.constraints,
  }));
};

const _getAlterationScore = (attribute: AttributeUnpacked) => {
  if (attribute.is_extra) {
    return 20;
  }
  if (attribute.is_missing) {
    return 10;
  }
  // not extra, not missing = originally in schema, not advised to be removed
  return 0;
};

const existingSort = (a: AttributeUnpacked, b: AttributeUnpacked) => {
  return _getAlterationScore(a) - _getAlterationScore(b);
};

export interface UseTemplatingProps {
  challengeId: string;
  snapshotId: string;
  templateId: string;
  isAutoSort?: boolean;
}

type ResetToPropsOptions = {
  isOverwriteLabels: boolean;
  onSuccess?: (
    newOriginals: AttributeUnpacked[],
    filtered: AttributeUnpacked[]
  ) => void;
  filter?: (attr: AttributeUnpacked) => boolean;
};
type ResetToProps = (
  newOriginals: AttributeUnpacked[],
  options?: ResetToPropsOptions
) => void;
const defaultResetOptions: ResetToPropsOptions = { isOverwriteLabels: true };

export type UseTemplatingType = {
  labels: ChangeableAttribute[];
  labelsOriginalMap: Map<string, AttributeUnpacked>;
  autocompleteSuggestions: string[];
  resetTo: ResetToProps;
  /** also usable for init. By default overwrites populated labels */
  addLabel: (labelName: string) => void;
  removeLabel: (labelName: string) => void;
  editLabel: (editedLabel: ChangeableAttribute) => void;
  challengeId: string;
  snapshotId: string;
  templateId: string;
  toDataLabels: () => DataLabelsDetailed;
  invalidate: () => void;
};

export const useTemplating = ({
  challengeId,
  snapshotId,
  templateId,
  isAutoSort = true,
}: UseTemplatingProps): UseTemplatingType => {
  const [labels, _setLabels] = useState<ChangeableAttribute[]>([]);
  const [orgTemplateId, setOrgTemplateId] = useState(templateId);
  const [labelsOriginalMap, setLabelsOriginalMap] = useState<
    Map<string, AttributeUnpacked>
  >(new Map());
  const [autocompleteSuggestions, setAutocompleteSuggestions] = useState<
    string[]
  >([]);
  const entityCount = useMemo(
    () => countEntities(Array.from(labelsOriginalMap.values())),
    [labelsOriginalMap]
  );
  const [isFresh, setIsFresh] = useState(true);
  const queryClient = useQueryClient();
  const invalidateSchemaLossy = useInvalidateSchemaLossy(
    snapshotId,
    templateId
  );

  const setLabels = useCallback(
    (newLabels: React.SetStateAction<ChangeableAttribute[]>) => {
      if (Array.isArray(newLabels)) {
        _setLabels(isAutoSort ? [...newLabels].sort(existingSort) : newLabels);
      } else {
        _setLabels((prev) => {
          const resultingLabels = newLabels(prev);
          return isAutoSort
            ? [...resultingLabels].sort(existingSort)
            : resultingLabels;
        });
      }
    },
    [_setLabels, isAutoSort]
  );

  const editLabel = useCallback(
    (editedLabel: ChangeableAttribute) => {
      setLabels((prev) => {
        const index = prev.findIndex((l) => l.name === editedLabel.name);
        if (index === -1) {
          return prev;
        }
        return [
          ...prev.slice(0, index),
          expandLabel(editedLabel, editLabel), // update setters
          ...prev.slice(index + 1),
        ];
      });
    },
    [setLabels]
  );

  const resetTo = useCallback<ResetToProps>(
    (newOriginals: AttributeUnpacked[], options = defaultResetOptions) => {
      if (!options.isOverwriteLabels && !isFresh) {
        return;
      }
      const filter = options.filter || (() => true);
      const filtered = newOriginals.filter(filter);
      setLabels(filtered.map((attr) => expandLabel(attr, editLabel)));
      setAutocompleteSuggestions(
        newOriginals.filter((attr) => !filter(attr)).map((attr) => attr.name)
      );
      const newMap = new Map<string, AttributeUnpacked>();
      newOriginals.forEach((attr) => newMap.set(attr.name, attr));
      setLabelsOriginalMap(newMap);
      setIsFresh(newOriginals.length === 0);
      options.onSuccess?.(newOriginals, filtered);
    },
    [
      setLabels,
      setAutocompleteSuggestions,
      setLabelsOriginalMap,
      editLabel,
      isFresh,
    ]
  );

  const addLabel = useCallback(
    (labelName: string) => {
      if (!labelName) {
        // empty string
        return;
      }
      if (getMatch(labels, labelName)) {
        // already exists
        return;
      }
      const original = labelsOriginalMap.get(labelName);
      if (original) {
        setLabels([...labels, expandLabel(original, editLabel)]);
      } else {
        setLabels([
          ...labels,
          expandLabel(newLabel(labelName, entityCount), editLabel),
        ]);
      }
      setAutocompleteSuggestions((prev) =>
        prev.filter((name) => name !== labelName)
      );
    },
    [labels, labelsOriginalMap, entityCount, setLabels, editLabel]
  );

  const removeLabel = useCallback(
    (labelName: string) => {
      const newLabels = labels.filter((label) => label.name !== labelName);
      if (newLabels.length === labels.length) {
        // no change - label not found
        return;
      }

      const original = labelsOriginalMap.get(labelName);
      setLabels(newLabels);
      if (original) {
        setAutocompleteSuggestions((prev) => [...prev, labelName]);
      }
    },
    [labels, labelsOriginalMap, setLabels]
  );

  const toDataLabels = useCallback(() => _toDataLabels(labels), [labels]);

  useEffect(() => {
    if (orgTemplateId !== templateId) {
      setOrgTemplateId(templateId);
      resetTo([]);
    }
  }, [orgTemplateId, templateId, resetTo]);

  const invalidate = useCallback(() => {
    resetTo([]); // if labels are not empty, newly fetched labels will not overwrite them
    queryClient.invalidateQueries({
      queryKey: genSnapshotTemplatingUnpackedKey(snapshotId, templateId),
    });
    queryClient.invalidateQueries({
      queryKey: genSnapshotTemplatingPackedKey(snapshotId, templateId),
    });
    queryClient.invalidateQueries({
      queryKey: genChallengeDetailsKey(challengeId),
    });
    invalidateSchemaLossy();
    // if it wasnt save_as, it would also invalidate genTemplateKey
  }, [
    resetTo,
    queryClient,
    snapshotId,
    templateId,
    challengeId,
    invalidateSchemaLossy,
  ]);

  return {
    labels,
    labelsOriginalMap,
    autocompleteSuggestions,
    resetTo,
    addLabel,
    removeLabel,
    editLabel,
    challengeId,
    snapshotId,
    templateId,
    toDataLabels,
    invalidate,
  };
};
