import {
  Button,
  CircularProgress,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import PageTitle from "../../../components/PageTitle";
import PasswordInput from "./PasswordInput";
import { useGoogleLogin } from "@react-oauth/google";
import googleLogo from "../../../resources/icons8-google.svg";
import { useState } from "react";
import { useMutation } from "react-query";
import { postGoogleCode, postPasswordLogin } from "../LocalOperations";
import { UseredTokenPair } from "../../../types/TokenPair";
import { BackendError } from "../../../types/BackendError";
import ErrorAlert from "../../../components/ErrorAlert";
import { setTokenPair } from "../../../utils/TokenManagement";
import { useNavigate, Link } from "react-router-dom";
import { setStorageUser } from "../../../utils/LoadUser";
import { useUserState } from "../../../hooks/UserStateProvider";
import { default as AccountCreationTab } from "../../../types/AccountCreationTabsEnum";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<undefined | BackendError>(undefined);
  const [, setUser] = useUserState();

  const googleLoginCode = useGoogleLogin({
    onSuccess: (codeResponse) => qGoogleLoginCode.mutate(codeResponse.code),
    flow: "auth-code",
  });

  const navigate = useNavigate();

  const onError = (error: BackendError) => {
    setError(error);
  };
  const startLogin = () => {
    setError(undefined);
  };
  const onSuccess = (useredTokenPair: UseredTokenPair) => {
    setTokenPair(useredTokenPair); // remember into local storage
    // setUser - to display email in top right who logged in and for compatibility
    const user = { email: useredTokenPair.email, id: useredTokenPair.user_id };
    setUser(user);
    setStorageUser(user);
    navigate("/"); // home page
  };

  const qGoogleLoginCode = useMutation<
    UseredTokenPair,
    BackendError,
    undefined | string
  >(postGoogleCode, {
    onError: onError,
    onSuccess: onSuccess,
  });
  const qPasswordLogin = useMutation<UseredTokenPair, BackendError, void>(
    () => postPasswordLogin(email, password),
    {
      onError: onError,
      onSuccess: onSuccess,
    }
  );

  const isLogging =
    qGoogleLoginCode.isLoading || // submitting google credentials to backend
    qPasswordLogin.isLoading || // submitting user+pass to backend
    qGoogleLoginCode.isSuccess || // ok. redirecting
    qPasswordLogin.isSuccess; // ok. redirecting

  return (
    <>
      <Container
        className="containerMain"
        style={{ width: "100%", maxWidth: "700px" }}
      >
        <PageTitle header="Login to your account" />
        <Typography>
          Don't have one? Register{" "}
          <Link to={`/users/new/?tab=${AccountCreationTab.SelectRole}`} style={{ fontWeight: "bold" }}>
            here
          </Link>
          
        </Typography>
        <div className="br" />
        <div className="gapVerticalMini loose-column">
          <TextField
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="text-field-medium"
          />
          <PasswordInput
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="text-field-medium"
          />
          <Button
            variant="contained"
            className="button-medium"
            onClick={() => {
              startLogin();
              qPasswordLogin.mutate();
            }}
            endIcon={isLogging ? <CircularProgress size="25px" /> : undefined}
            disabled={isLogging || !email || !password}
          >
            Login
          </Button>
          <Typography>OR</Typography>
          <Button
            startIcon={
              <img
                src={googleLogo}
                alt="Google icon"
                width="24px"
                height="24px"
              />
            }
            className="button-medium"
            style={{
              color: "snow",
              backgroundColor: "black",
              textTransform: "none",
            }}
            endIcon={isLogging ? <CircularProgress size="25px" /> : undefined}
            disabled={isLogging}
            onClick={() => {
              startLogin();
              googleLoginCode();
            }}
          >
            Continue with Google
          </Button>
          {!!error && <ErrorAlert value={error} />}
        </div>
      </Container>
    </>
  );
};

export default Login;
