const root = process.env.REACT_APP_BACKEND_URL;

const API = {
  root: root,
  postSampleFile: root + "/generate_labels",
  getFrequencies: root + "/frequencies",
  postChallengeNew: root + "/challenges",
  postProjectNew: root + "/projects/create",
  getUsersBasic: root + "/users",
  getRuntimes: root + "/runtimes",
  getNamedTemplates: `${root}/templates/named`,
  deleteValidatorInstance: `${root}/validators/instance`,
  getChallengesBrief: `${root}/challenges`,
  getFailingAutoruns: `${root}/code/autoruns/failing`,
  getFailingValidations: `${root}/validations/autoruns/failing`,
  postGoogleLogin: `${root}/security/auth/google`,
  postGoogleLoginCode: `${root}/security/auth/google/code`,
  postPasswordLogin: `${root}/security/auth/pass`,
  postRefreshToken: `${root}/security/token/refresh`,
  postImpersonation: `${root}/security/token/impersonate`,
  getMyPerms: `${root}/security/perms`,
  getMyAPIKey: `${root}/security/api_key`,
  getIsPassTemporary: `${root}/security/pass/temporary/is`,
  postTemporaryPassChange: `${root}/security/pass/temporary/change`,
  createUser: `${root}/users/create`,
  getProjects: `${root}/projects`,
  getUserProjects: `${root}/projects/user_projects`,
  patchUserDetails: `${root}/users/user_details`,
  getAllUserSubscriptions: `${root}/subscriptions/user_subscriptions`,
  updateSubscription: `${root}/subscriptions/update_subscription`,
  getAllChallengesCount: `${root}/dashboard/all_challenges_count`,
  getFreeChallengesCount: `${root}/dashboard/free_challenges_count`,
  getSolverChallengesCount: `${root}/dashboard/solver_challenges_count`,

  genGetChallengesWithErrorsCount: (showUserSolvings: boolean) =>
    `${root}/dashboard/challenges_with_errors_count?show_user_solvings=${showUserSolvings}`,

  genGetChallengesWithWarningsCount: (showUserSolvings: boolean) =>
    `${root}/dashboard/challenges_with_warnings_count?show_user_solvings=${showUserSolvings}`,

  genGetChallengesNeedFixCount: (showUserSolvings: boolean) =>
    `${root}/dashboard/challenges_need_fix_count?show_user_solvings=${showUserSolvings}`,

  genGetChallengesWaitingForReviewCount: (showUserSolvings: boolean) =>
    `${root}/dashboard/challenges_waiting_for_review_count?show_user_solvings=${showUserSolvings}`,

  genGetChallengesCreationSLABreachedCount: (showUserSolvings: boolean) =>
    `${root}/dashboard/challenges_creation_sla_breached_count?show_user_solvings=${showUserSolvings}`,

  genGetChallengesDeliverySLABreachedCount: (showUserSolvings: boolean) =>
    `${root}/dashboard/challenges_delivery_sla_breached_count?show_user_solvings=${showUserSolvings}`,

  genGetAllProjectChallengesCount: (projectId: string) =>
    `${root}/projects/${projectId}/all_challenges_count`,

  genGetFreeProjectChallengesCount: (projectId: string) =>
    `${root}/projects/${projectId}/free_challenges_count`,

  genGetSolverProjectChallengesCount: (projectId: string) =>
    `${root}/projects/${projectId}/solver_challenges_count`,

  genGetProjectChallengesWithErrorsCount: (
    projectId: string,
    showUserSolvings: boolean
  ) =>
    `${root}/projects/${projectId}/challenges_with_errors_count?show_user_solvings=${showUserSolvings}`,

  genGetProjectChallengesWithWarningsCount: (
    projectId: string,
    showUserSolvings: boolean
  ) =>
    `${root}/projects/${projectId}/challenges_with_warnings_count?show_user_solvings=${showUserSolvings}`,

  genGetProjectChallengesNeedFixCount: (
    projectId: string,
    showUserSolvings: boolean
  ) =>
    `${root}/projects/${projectId}/challenges_need_fix_count?show_user_solvings=${showUserSolvings}`,

  genGetProjectChallengesWaitingForReviewCount: (
    projectId: string,
    showUserSolvings: boolean
  ) =>
    `${root}/projects/${projectId}/challenges_waiting_for_review_count?show_user_solvings=${showUserSolvings}`,

  genGetProjectChallengesCreationSLABreachedCount: (
    projectId: string,
    showUserSolvings: boolean
  ) =>
    `${root}/projects/${projectId}/challenges_creation_sla_breached_count?show_user_solvings=${showUserSolvings}`,

  genGetProjectChallengesDeliverySLABreachedCount: (
    projectId: string,
    showUserSolvings: boolean
  ) =>
    `${root}/projects/${projectId}/challenges_delivery_sla_breached_count?show_user_solvings=${showUserSolvings}`,

  genGetProject: (projectId: string) => `${root}/projects/${projectId}`,

  genGetProjectUsers: (projectId: string) =>
    `${root}/projects/${projectId}/users`,

  genPostAddUserToProject: (projectId: string) =>
    `${root}/projects/${projectId}/add_user`,

  genUpdateUserProjectRole: (
    projectId: string,
    userId: string,
    projectRole: string
  ) =>
    `${root}/projects/${projectId}/update_user_role/${userId}?project_role=${projectRole}`,

  genDeleteChallenge: (challengeId: string) =>
    `${root}/challenges/${challengeId}/delete`,

  genPatchUndeleteChallenge: (challengeId: string) =>
    `${root}/challenges/${challengeId}/restore`,

  genReleaseChallenge: (challengeId: string) =>
    `${root}/challenges/${challengeId}/release`,

  genUpdateChallengeFrequency: (challengeId: string, frequency_name: string) =>
    `${root}/challenges/${challengeId}/frequency?frequency_name=${frequency_name}`,

  genUpdateChallengeProject: (challengeId: string, project_id: string) =>
    `${root}/challenges/${challengeId}/project?project_id=${project_id}`,

  genPatchChallengeDetails: (challengeId: string) =>
    `${root}/challenges/${challengeId}/details`,

  getChallengeSendEmail: (challengeId: string) =>
    `${root}/challenges/${challengeId}/send_email`,

  getChallengeSubscription: (challengeId: string) =>
    `${root}/challenges/${challengeId}/subscription`,

  genDeleteChallengeSubscription: (challengeId: number) =>
    `${root}/subscriptions/${challengeId}/delete_subscription`,

  getChallengeSendEmailv2: (challengeId: string) =>
    `${root}/challenges/v2/${challengeId}/send_email`,

  genUpdateUserStatus: (userId: string, status: string) =>
    `${root}/users/${userId}?status=${status}`,

  genGetChallengeDetailed: (id: number | string) => `${root}/challenges/${id}`,

  genPatchChallengeReserve: (challengeId: string) =>
    `${root}/challenges/${challengeId}/reserve`,

  genGetIsChallengeReservedByUser: (challengeId: string, userId: string) =>
    `${root}/challenges/${challengeId}/reserved/by/${userId}`,

  genPostCode: (challengeId: string) =>
    `${root}/challenges/${challengeId}/code`,

  genGetCodeRunDetails: (
    challengeId: string,
    codeRunNumber: string,
    userId: string
  ) => `${root}/challenges/${challengeId}/code/${codeRunNumber}/user/${userId}`,

  genGetCodeRunHeadsByChallengeUser: (challengeId: string, userId: string) =>
    `${root}/challenges/${challengeId}/code/all/user/${userId}`,

  genGetCodeRunHeadByChallengeUserNumber: (
    challengeId: string,
    userId: string,
    codeRunNumber: string
  ) =>
    `${root}/challenges/${challengeId}/code/${codeRunNumber}/user/${userId}/head`,

  genGetIsChallengeHasCodeByUser: (challengeId: string, userId: string) =>
    `${root}/challenges/${challengeId}/code/exists/by/${userId}`,

  genHeadersUserToken: (userToken: string) => {
    return { "user-token": userToken };
  },

  genPatchChallengeRunTime: (challengeId: string) =>
    `${root}/challenges/${challengeId}/run/time/limit`,

  genPatchAutorunCodeRun: (challengeId: string, userId: string) =>
    `${root}/challenges/${challengeId}/code/autorun/user/${userId}`,

  genGetTemplate: (templateId: string) => `${root}/templates/${templateId}`,

  genPatchTemplateName: (templateId: string) =>
    `${root}/templates/${templateId}/name`,

  genGetTemplateChallenges: (templateId: string) =>
    `${root}/templates/${templateId}/challenges`,

  genGetTemplateFormat: (templateId: string) =>
    `${root}/templates/${templateId}/format`,

  genPatchAutorunRecreate: (challengeId: string, userId: string) =>
    `${root}/challenges/${challengeId}/code/autorun/user/${userId}/recreate`,

  genGetSnapshot: (snapshotId: string) => `${root}/snapshots/${snapshotId}`,

  genGetSnapshotStatistics: (snapshotId: string) =>
    `${root}/snapshots/${snapshotId}/stats`,

  genGetSnapshotHead: (snapshotId: string) =>
    `${root}/snapshots/${snapshotId}/head`,

  genGetSnapshotPreview: (snapshotId: string) =>
    `${root}/snapshots/${snapshotId}/preview`,

  genGetSnapshotValidations: (snapshotId: string) =>
    `${root}/snapshots/${snapshotId}/validations`,

  genGetValidationsSummary: (snapshotId: string) =>
    `${root}/snapshots/${snapshotId}/status`,

  genGetJsonSchemaValidator: (templateId: string) =>
    `${root}/templates/${templateId}/validators/jsonschema`,

  genPatchJsonSchemaValidator: (validatorId: string) =>
    `${root}/validators/instance/${validatorId}/jsonschema`,

  genGetCustomCodeValidators: (templateId: string) =>
    `${root}/templates/${templateId}/validators/customcode`,

  genPostCustomCodeValidator: (templateId: string) =>
    `${root}/templates/${templateId}/validators/customcode`,

  genPatchCustomCodeValidator: (validatorId: string) =>
    `${root}/validators/instance/${validatorId}/customcode`,

  genGetLastSnapshot: (challengeId: string) =>
    `${root}/snapshots/latest/?challenge_id=${challengeId}`,

  genGetSnapshotExtra: (snapshotId: string) =>
    `${root}/snapshots/${snapshotId}/extra`,

  genGetChallengeSLA: (challengeId: string) =>
    `${root}/challenges/${challengeId}/sla`,

  genGetChallengeSolvings: (challengeId: string) =>
    `${root}/challenges/${challengeId}/solve/history`,

  genGetCurrentUser: () => `${root}/users/me`,

  genGetUser: (userId: string) => `${root}/users/get/${userId}`,

  genPatchForceAutorun: (
    challengeId: string,
    challengeRunNumber: string,
    userId: string
  ) =>
    `${root}/challenges/${challengeId}/code/${challengeRunNumber}/autorun/user/${userId}/auto`,

  genDownloadXLS: (
    challengeId: string,
    challengeRunNumber: string,
    userId: string,
    api_key: string | null
  ) => {
    var base_url = `${root}/challenges/${challengeId}/code/${challengeRunNumber}/user/${userId}/download`;

    // if <api_key> is provided, use this for authentification
    if (api_key != null) {
      base_url += `?api_key=${api_key}`;
    }

    return base_url;
  },

  genChallengeDownloadXLS: (challengeId: string) => {
    return `${root}/challenges/${challengeId}/code/download`;
  },

  genPatchCancelRun: (
    challengeId: string,
    challengeRunNumber: string,
    userId: string
  ) =>
    `${root}/challenges/${challengeId}/code/${challengeRunNumber}/user/${userId}/cancel`,

  genPatchForceRun: (
    challengeId: string,
    challengeRunNumber: string,
    userId: string
  ) =>
    `${root}/challenges/${challengeId}/code/${challengeRunNumber}/user/${userId}/rerun`,

  genGetCodeRunReview: (
    challengeId: string,
    challengeRunNumber: string,
    userId: string
  ) =>
    `${root}/solving/${challengeId}/code/${challengeRunNumber}/user/${userId}/review`,

  genGetSolving: (challengeId: string, userId: string) =>
    `${root}/solving/${challengeId}/user/${userId}`,

  genGetPackedTemplating: (snapshotId: string) =>
    `${root}/snapshots/${snapshotId}/templating/packed`,

  genGetUnpackedTemplating: (snapshotId: string) =>
    `${root}/snapshots/${snapshotId}/templating/unpacked`,

  genPostEvaluateTemplatingImpact: (snapshotId: string) =>
    `${root}/snapshots/${snapshotId}/templating/evaluate/impact`,

  genGetSchemaCustomizationBySnapId: (snapshotId: string) =>
    `${root}/snapshots/${snapshotId}/schema-customization`,

  genPostCodeRunReview: (
    challengeId: string,
    challengeRunNumber: string,
    userId: string
  ) =>
    `${root}/solving/${challengeId}/code/${challengeRunNumber}/user/${userId}/review`,

  genPostTemplateSaveAs: (templateId: string) =>
    `${root}/templates/${templateId}/fork`,
};

export default API;
