import {
  Grid,
  MenuItem,
  TextField,
  Typography,
  Container,
  Paper,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import FinishButton from "../../components/FinishButton";
import { useQueryCurrentUserDetails } from "../../hooks/UseQueryUserDetails";
import SearchProject from "./components/SearchProject";
import ProjectCreationForm from "../../components/ProjectCreationForm";

interface CompleteIn {
  email: string;
  name: string;
  budget: number;
  budgetLength: number;
  selectedProjectId: number;
  setEmail: (email: string) => void;
  setName: (name: string) => void;
  setBudget: (budget: number) => void;
  setBudgetLength: (budgetLength: number) => void;
  setSelectedProjectId: (projectId: number) => void;
  onCompleteQuerySuccess: (
    email: string,
    name: string,
    budget: number,
    budgetLength: number,
    selectedProjectId: number
  ) => void;
}

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const months = Array.from(Array(24), (_, i) => 24 - i);
const monthsMenuItems = months.map((monthLength) => (
  <MenuItem value={monthLength} key={monthLength}>
    {monthLength} month(s)
  </MenuItem>
));

const CompleteQuery = ({
  email,
  name,
  budget,
  budgetLength,
  selectedProjectId,
  setEmail,
  setName,
  setBudget,
  setBudgetLength,
  setSelectedProjectId,
  onCompleteQuerySuccess,
}: CompleteIn) => {
  const [isError, setIsError] = useState(false);
  const { data: user } = useQueryCurrentUserDetails();

  useEffect(() => {
    if (user && user.email && !email) {
      setEmail(user.email);
    }
  }, [user, email, setEmail]);

  const onEmailChange = (newEmail: string) => {
    setEmail(newEmail);
    setIsError(!emailRegex.test(newEmail));
  };

  const onBudgetChange = (newBudgetStr: string) => {
    let parsedBudget = parseInt(newBudgetStr);
    parsedBudget = isNaN(parsedBudget) ? 12 : parsedBudget;
    parsedBudget = parsedBudget < 1 ? 12 : parsedBudget;
    setBudget(parsedBudget);
  };

  const onBudgetLengthChange = (newLengthStr: string | number) => {
    if (typeof newLengthStr === "number") {
      setBudgetLength(newLengthStr);
      return;
    }
    let parsedLength = parseInt(newLengthStr);
    parsedLength = isNaN(parsedLength) ? 1 : parsedLength;
    setBudgetLength(parsedLength);
  };

  const isDisableFinish = () => {
    return (
      isError ||
      email.trim().length < 1 ||
      name.trim().length < 1 ||
      selectedProjectId === 0
    );
  };

  return (
    <Container className="cardGrid">
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h5">Challenge name</Typography>
      </Grid>
      <Paper
        elevation={0}
        sx={{ border: "none", padding: 3, marginBottom: 3, marginTop: 3 }}
      >
        <Grid container>
          <Grid item xs={5} sm={5} md={5}>
            <TextField
              id="Challenge-name-input"
              label="Challenge name"
              variant="outlined"
              fullWidth
              helperText="Required"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
        </Grid>
      </Paper>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h5">Budget</Typography>
      </Grid>
      <Paper
        elevation={0}
        sx={{ border: "none", padding: 3, marginBottom: 3, marginTop: 3 }}
      >
        <Grid container>
          <Grid item xs={3} sm={3} md={3} marginRight={2}>
            <TextField
              id="Budget-input"
              label="Amount"
              variant="outlined"
              fullWidth
              helperText="Required"
              required
              type="number"
              value={budget}
              onChange={(e) => onBudgetChange(e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={3} sm={3} md={3}>
            <TextField
              id="Payment-frequency-select"
              label="Payment frequency"
              variant="outlined"
              select
              fullWidth
              value={budgetLength}
              onChange={(e) => onBudgetLengthChange(e.target.value)}
            >
              {monthsMenuItems}
            </TextField>
          </Grid>
        </Grid>
      </Paper>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h5">Contact</Typography>
      </Grid>
      <Paper
        elevation={0}
        sx={{ border: "none", padding: 3, marginBottom: 3, marginTop: 3 }}
      >
        <Grid container>
          <Grid item xs={5} sm={5} md={5} marginRight={2}>
            <TextField
              id="Email-input"
              label="Email address"
              helperText="Required"
              required
              variant="outlined"
              fullWidth
              value={email}
              onChange={(e) => onEmailChange(e.target.value)}
            />
          </Grid>
        </Grid>
      </Paper>

      <Accordion
        sx={{
          boxShadow: "none",
          border: "none",
          "&:before": {
            display: "none",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="project-content"
          id="project-header"
        >
          <Typography>Create New Project</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ProjectCreationForm />
        </AccordionDetails>
      </Accordion>
      <Grid item xs={12} sm={12} md={12} marginTop={2}>
        <Typography variant="h5">Assign challenge to project</Typography>
      </Grid>
      <Paper
        elevation={0}
        sx={{ border: "none", padding: 3, marginBottom: 3, marginTop: 3 }}
      >
        <SearchProject
          label="Select project"
          selectedProjectId={selectedProjectId}
          setSelectedProjectId={setSelectedProjectId}
        />
      </Paper>
      <Grid item xs={12} sm={12} md={12} marginTop={2}>
        <FinishButton
          disabled={isDisableFinish()}
          onClick={() =>
            onCompleteQuerySuccess(
              email,
              name,
              budget,
              budgetLength,
              selectedProjectId
            )
          }
        />
      </Grid>
    </Container>
  );
};

export default CompleteQuery;
