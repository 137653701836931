import React, { useState } from "react";
import { Typography, Box, Chip } from "@mui/material";
import { useQueryUsersBasic } from "../hooks/UseQueryUsersBasic";
import { useQuery } from "react-query";
import UserSelector from "./UserSelector";
import { User } from "../types/Users";
import { Solvings } from "../types/Solvings";
import { SLA } from "../types/SLA";
import { BackendError } from "../types/BackendError";
import { getChallengeSolvings, getChallengeSLA } from "../pages/ChallengeViewSteps/LocalOperations";

enum Preset {
  MAIN_CONTRIBUTOR = "main_contributor",
  ALL_TASK_CONTRIBUTORS = "all_task_contributors",
  ALL_CONTRIBUTORS = "all_contributors",
}

interface MultiSelectUserProps {
  selectedUserIds: string[];
  onChangeSelectedUsers: (selectedIds: string[]) => void;
  labelId?: string;
  challengeId: string;
}

const MultiSelectUser = ({
  selectedUserIds,
  onChangeSelectedUsers,
  labelId,
  challengeId,
}: MultiSelectUserProps) => {
  const query = useQueryUsersBasic();
  const qSolvings = useQuery<Solvings, BackendError>(["solvings"], () =>
    getChallengeSolvings(challengeId)
  );
  const qSLA = useQuery<SLA, BackendError>(["sla"], () =>
    getChallengeSLA(challengeId)
  );

  const [selectedPreset, setSelectedPreset] = useState<Preset | null>(null);

  const handlePresetSelection = (preset: Preset) => {
    setSelectedPreset(preset === selectedPreset ? null : preset);
    setPresetUsers(preset);
  };

  const setPresetUsers = (preset: Preset) => {
    switch (preset) {
      case Preset.MAIN_CONTRIBUTOR:
        if (qSLA.data?.solver) {
          const solver = query.data?.find((user) => user.email === qSLA.data.solver);
          if (solver) onChangeSelectedUsers([solver.id]);
        }
        break;
      case Preset.ALL_TASK_CONTRIBUTORS:
        const solversIds = qSolvings.data?.map((solving) => solving.user_id) || [];
        onChangeSelectedUsers(solversIds);
        break;
      case Preset.ALL_CONTRIBUTORS:
        const allContributorsIds = query.data?.map((user) => user.id) || [];
        onChangeSelectedUsers(allContributorsIds);
        break;
    }
  };

  return (
    <Box>
      <Box marginBottom={2}>
        <Typography>Select receiver</Typography>
        <Chip
          variant="outlined"
          onClick={() => handlePresetSelection(Preset.MAIN_CONTRIBUTOR)}
          sx={{ marginRight: 1 }}
          color={selectedPreset === Preset.MAIN_CONTRIBUTOR ? "primary" : undefined}
          label="Main contributor"
        />
        <Chip
          variant="outlined"
          onClick={() => handlePresetSelection(Preset.ALL_TASK_CONTRIBUTORS)}
          sx={{ marginRight: 1 }}
          color={selectedPreset === Preset.ALL_TASK_CONTRIBUTORS ? "primary" : undefined}
          label="All tasks contributors"
        />
        <Chip
          variant="outlined"
          onClick={() => handlePresetSelection(Preset.ALL_CONTRIBUTORS)}
          sx={{ marginRight: 1 }}
          color={selectedPreset === Preset.ALL_CONTRIBUTORS ? "primary" : undefined}
          label="All contributors"
        />
      </Box>
      {query.isSuccess && (
        <UserSelector
          selectedUserIds={selectedUserIds}
          onChange={onChangeSelectedUsers}
          users={query.data}
          label="Select receiver"
        />
      )}
    </Box>
  );
};

export default MultiSelectUser;
