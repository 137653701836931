import { createTheme, lighten, checkboxClasses } from "@mui/material";
import { valueCompletenessBarClasses } from "../pages/solutionSubmission/components/ValueCompletenessBar";

export const provideTheme = createTheme({
  palette: {
    success: {
      main: "#39BF7F",
      light: lighten("#39BF7F", 0.8),
      dark: lighten("#39BF7F", 0.8),
    },
    primary: {
      main: "#263238",
      light: lighten("#263238", 0.62),
    },
    secondary: {
      main: "#717171",
      light: lighten("#717171", 0.8),
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: "normal",
          color: "#717171",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          [`&.${checkboxClasses.checked}:not(.${checkboxClasses.disabled})`]: {
            color: "#1976d2", // Default blue color
          },
        },
      },
    },
    // custom styled component to substitute Box.
    // MaterialUI does not allow overriding MuiBox in a theme.
    // @ts-ignore
    MultiColorBar: {
      styleOverrides: {
        root: {
          [`&.${valueCompletenessBarClasses.defined}`]: {
            backgroundColor: "#39BF7F", // electric green
          },
          [`&.${valueCompletenessBarClasses.null}`]: {
            backgroundColor: "#717171", // light gray
          },
          [`&.${valueCompletenessBarClasses.undefined}`]: {
            backgroundColor: "#263238 ", // blackish
          },
        },
      },
    },
  },
});
