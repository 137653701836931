import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { TemplatingEditContext } from "../contexts/TemplatingEditContext";
import { useMutation } from "react-query";
import { BackendError } from "../../../types/BackendError";
import { postTemplateSaveAs } from "../LocalOperations";
import ErrorAlert from "../../../components/ErrorAlert";
import { TemplateFork } from "../../../types/TemplateFork";

interface TemplatingSaveAsProps {
  style?: React.CSSProperties;
  onSuccess?: () => void;
}

export const TemplatingSaveAs = ({
  style,
  ...props
}: TemplatingSaveAsProps) => {
  const [name, setName] = useState("");
  const templating = useContext(TemplatingEditContext);
  const qSaveAs = useMutation<
    any,
    BackendError,
    { templateId: string; data: TemplateFork }
  >((packed) => postTemplateSaveAs(packed.templateId, packed.data), {
    onSuccess: () => {
      templating?.invalidate();
      props.onSuccess?.();
    },
  });
  if (!templating) {
    return null;
  }
  return (
    <Card style={style} variant="outlined">
      <CardContent>
        <Typography variant="h6">Save As</Typography>
        <Typography>
          New template will be forked for current challenge.
        </Typography>
        <Typography>
          Reusable template? Name it. Otherwise leave empty name to make the
          template private.
        </Typography>
        <Stack direction="row" style={{ paddingTop: "11px" }}>
          <TextField
            label="Save as name"
            style={{ marginRight: "11px" }}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Button
            variant="outlined"
            onClick={() =>
              qSaveAs.mutate({
                templateId: templating.templateId,
                data: {
                  data_labels: templating.toDataLabels(),
                  new_name: name || undefined,
                  transfer_challenges_id: [templating.challengeId],
                },
              })
            }
            disabled={qSaveAs.isLoading}
            endIcon={qSaveAs.isLoading ? <CircularProgress /> : undefined}
            style={{ height: "55px" }}
          >
            Save As
          </Button>
        </Stack>
        {!!qSaveAs.error && <ErrorAlert isClosable value={qSaveAs.error} />}
      </CardContent>
    </Card>
  );
};
