/** When component supports text truncation, how many symbols shall be printed if text is quite long. */
export const CHAR_LIMIT_FOR_TRUNCATABLE = 1000; // backend returns ~2500 chars

/** How many challenges-brief to load per infQuery page? */
export const CHALLENGES_PER_PAGE = 30;

/** How many challenge suggestions to show as autocomplete */
export const CHALLENGES_PER_TEXT_SEARCH = 4;

/** How many snapshot structure differences in comparison to template shall be shown in preview */
export const SNAPSHOT_TEMPLATE_DIFFERENCES_PER_PREVIEW = 4;

/** This is the id of of the user that is not logged in */
export const UNLOGGED_ID = "anonymous";

/** enum for possible challenge call to actions */
export const enum ActionType {
  SHOW_MORE = "show_more",
  RESERVE = "reserve",
  SHOW_MY_RUNS = "show_my_runs",
  SHOW_ALL_RUNS = "show_all_runs",
  SHOW_LAST_RUN = "show_last_run",
  SHOW_REVIEW = "show_review",
  RELEASE = "release",
  SUBMIT = "submit",
  RUN_HOURLY = "run_hourly",
  RUN_DAILY = "run_daily",
  MOVE_TO_PROJECT = "move_to_project",
}

/** enum of supported permissions */
export const enum Perm {
  VIEW_OWN_CHALLENGE = "view_own_challenge",
  VIEW_SOLVING_CHALLENGE = "view_solving_challenge",
  VIEW_ANY_CHALLENGE = "view_any_challenge",
  VIEW_FREE_CHALLENGE = "view_free_challenge",
  DOWNLOAD_OWN_EXPORT = "download_own_export",
  DOWNLOAD_SOLVING_EXPORT = "download_solving_export",
  DOWNLOAD_ANY_EXPORT = "download_any_export",
  SCHEDULE_AUTORUN = "schedule_autorun",
  VIEW_OWN_CODE = "view_own_code",
  VIEW_ANY_CODE = "view_any_code",
  VIEW_SOLVING_CODE = "view_solving_code",
  SUBMIT_AS_ANY = "submit_as_any",
  SUBMIT_AS_SELF = "submit_as_own",
  VIEW_ANY_VALIDATOR = "view_any_validator",
  MANAGE_ANY_VALIDATOR = "manage_any_validator",
  VIEW_NAMED_TEMPLATES = "view_named_templates",
  VIEW_ANY_TEMPLATE = "view_any_template",
  VIEW_ANY_DATA_FORMAT = "view_any_data_format",
  MANAGE_ANY_TEMPLATE = "manage_any_template",
  VIEW_LINKED_CHALLENGES = "view_linked_challenges",
  CANCEL_AUTORUN = "cancel_autorun",
  CANCEL_OWN_CODE = "cancel_own_code",
  CANCEL_ANY_CODE = "cancel_any_code",
  REVIEW_ANY_CODE = "review_any_code",
  MANAGE_RUN_SETTINGS = "manage_run_settings",
  DELETE_CHALLENGE = "delete_challenge",
  IMPERSONATE = "impersonate",
}

export const enum ValidationStatus {
  QUEUED = "queued",
  RUNNING = "running",
  SUCCESS = "success",
  SUCCESS_WITH_WARNING = "warning",
  DATA_FAILURE = "error",
  INTERNAL_FAILURE = "crash",
  TIMEOUT = "timeout",
}

// from sceemas.py::SimpleCodeRunStatus
export const enum CoderunStatus {
  QUEUED = "queued",
  RUNNING = "running",
  SUCCESS = "success",
  ERROR = "error",
  CRASH = "crash",
  TIMEOUT = "timeout",
}

export const enum ReservationStatus {
  FREE = "free",
  RESERVED = "reserved",
}
