import axios from "axios";
import API from "../../API";
import { UseredTokenPair } from "../../types/TokenPair";
import { axiosTokened } from "../../utils/AxiosInstances";
import NewAccountDetails from "../../types/NewAccountDetails";
import BooleanAnswer from "../../types/BooleanAnswer";

export const postGoogleCredentials = async (credentials?: string) => {
  const { data } = await axios.post<UseredTokenPair>(
    API.postGoogleLogin,
    undefined,
    {
      headers: { credentials: credentials ?? "" },
    }
  );
  return data;
};

export const postGoogleCode = async (authCode?: string) => {
  const { data } = await axios.postForm<UseredTokenPair>(
    API.postGoogleLoginCode,
    {
      auth_code: authCode ?? "",
    }
  );
  return data;
};

export const postPasswordLogin = async (email?: string, password?: string) => {
  const { data } = await axios.postForm<UseredTokenPair>(
    API.postPasswordLogin,
    {
      username: email,
      password: password,
    }
  );
  return data;
};

export const getIsPassTemporary = async () => {
  const { data } = await axiosTokened.get<BooleanAnswer>(
    API.getIsPassTemporary
  );
  return data;
};

export const postTemporaryPassChange = async (newPassword: string) => {
  await axiosTokened.postForm(API.postTemporaryPassChange, {
    new_password: newPassword,
  });
};

export const createUser = async (accountNew: NewAccountDetails) => {
  await axiosTokened.post(API.createUser, accountNew);
};