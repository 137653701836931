import { Typography } from "@mui/material";
import { ChallengeBrief } from "../../../types/ChallengesBrief";
import { useInView } from "react-intersection-observer";
import { SLA } from "../../../types/SLA";
import { getStatusClassname } from "../../../components/ColoredStatus";
import { useChallengeSLA } from "../../../hooks/UseChallengeSLA";

interface BannerChallengeStatusProps {
  challenge: ChallengeBrief;
}

const BannerChallengeStatus = ({ challenge }: BannerChallengeStatusProps) => {
  const qChallengeSLA = useChallengeSLA(challenge.id, {
    enabled: false,
    staleTime: 5 * 60 * 1000, // 5 min
  });
  const { ref } = useInView({
    triggerOnce: true,
    onChange: (isVisible: boolean) =>
      isVisible && qChallengeSLA.isIdle ? qChallengeSLA.refetch() : undefined,
  });

  const sla: any =
    qChallengeSLA.isSuccess && !!qChallengeSLA.data
      ? qChallengeSLA.data
      : qChallengeSLA.isIdle
      ? { is_violated: false, message: "unknown", solver: "unknown" }
      : qChallengeSLA.isLoading
      ? { is_violated: false, message: "loading...", solver: "loading..." }
      : { is_violated: true, message: "error", solver: "error" };

  return (
    <Typography
      ref={ref}
      className={sla.is_violated ? getStatusClassname("error") : undefined}
      component={"span"}
    >
      {sla.message}
    </Typography>
  );
};

export default BannerChallengeStatus;
