import { createContext, useContext, useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { User, UserContextType } from "../types/Users";
import createDefaultUser from "../utils/CreateDefaultUser";
import loadStorageUserOrInvalidUser from "../utils/LoadUser";
import { UNLOGGED_ID } from "../Const";

const UserContext = createContext<UserContextType>([
  createDefaultUser(),
  () => {
    console.log(
      "user context is not initialised - you called this fn way too early"
    );
  },
]);

export function useUserState() {
  return useContext(UserContext);
}

interface UserStateProviderProps {
  children: React.ReactNode;
  isRedirectOnAnonymous?: boolean;
}

export const UserStateProvider = ({ children, isRedirectOnAnonymous }: UserStateProviderProps) => {
  const [user, setUser] = useState<User>(() => loadStorageUserOrInvalidUser());
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isRedirectOnAnonymous || user.id !== UNLOGGED_ID) {
      return;
    }
    const path = location.pathname.toLowerCase();
    const keys = ["/login", "/users/new"];
    if (keys.find((key) => path.includes(key))) {
      return;
    }
    navigate("/login");
  }, [navigate, location, isRedirectOnAnonymous, user]);
  return (
    <UserContext.Provider value={[user, setUser]}>
      {children}
    </UserContext.Provider>
  );
};
