import { useMutation, useQuery } from "react-query";
import { useState } from "react";
import { Template } from "../../../types/Template";
import { BackendError } from "../../../types/BackendError";
import { getTemplateChallenges, patchTemplateName } from "../LocalOperations";
import EditableField from "./EditableField";
import ChapterTitle from "../../../components/ChapterTitle";
import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import {
  ChallengeBrief,
  ChallengesBrief,
} from "../../../types/ChallengesBrief";
import ErrorAlert from "../../../components/ErrorAlert";
import FormatValidators from "./FormatValidators";
import { useQueryTemplate } from "../../../hooks/UseQueryTemplate";

interface FormatTemplateProps {
  templateId: string;
}

const FormatTemplate = (props: FormatTemplateProps) => {
  const [name, setName] = useState("");

  const qTemplate = useQueryTemplate(props.templateId, {
    onSuccess: (template: Template) => setName(template.name ?? ""),
  });

  const qEditName = useMutation<void, BackendError, void>(() =>
    patchTemplateName(props.templateId, name)
  );

  const qChallenges = useQuery<ChallengesBrief, BackendError>(
    ["templateChallenges", props.templateId],
    () => getTemplateChallenges(props.templateId)
  );

  return (
    <>
      <ChapterTitle
        header="Template"
        subHeader="Giving a name to template enables it to become reusable when creating new challenge"
      />
      <EditableField
        label="Template name"
        fieldValue={name}
        onFieldChange={setName}
        onSave={qEditName.mutate}
        isLoading={qTemplate.isLoading || qEditName.isLoading}
        error={
          qTemplate.isError
            ? qTemplate.error
            : qEditName.isError
            ? qEditName.error
            : undefined
        }
        isEditSuccess={qEditName.isSuccess}
      />
      <ChapterTitle subHeader="Challenges, that use same template" />
      {qChallenges.isLoading && <CircularProgress />}
      {qChallenges.isError && (
        <ErrorAlert prefix="Error: " value={qChallenges.error} />
      )}
      {qChallenges.isSuccess && (
        <Box display="flex" justifyContent="center">
          <List dense style={{ justifyContent: "center" }}>
            {qChallenges.data ? (
              qChallenges.data.map((challenge: ChallengeBrief) => (
                <ListItem key={challenge.id}>
                  <ListItemText
                    primary={challenge.name}
                    secondary={`id: ${challenge.id}`}
                  />
                </ListItem>
              ))
            ) : (
              <ListItem>
                <ListItemText
                  primary="Whoops, no challenges use current template."
                  secondary="Even the challenge that you are currently viewing (it should not be this way)."
                />
              </ListItem>
            )}
          </List>
        </Box>
      )}
      <FormatValidators templateId={props.templateId} />
    </>
  );
};

export default FormatTemplate;
