import API from "../../API";
import { CodeRunReview } from "../../types/CodeRunReview";
import { DataLabelsDetailed } from "../../types/DataLabelsDetailed";
import { SchemaCustomization } from "../../types/SchemaCustomization";
import {
  SnapshotMinusTemplatePacked,
  SnapshotMinusTemplateUnpacked,
} from "../../types/SnapshotMinusTemplate";
import { TemplateFork } from "../../types/TemplateFork";
import { TemplatingImpactSummary } from "../../types/TemplatingImpactSummary";
import { axiosTokened } from "../../utils/AxiosInstances";

export const getCodeReview = async (
  challengeId: string,
  codeRunNumber: string,
  userId: string
) => {
  const { data } = await axiosTokened.get<CodeRunReview>(
    API.genGetCodeRunReview(challengeId, codeRunNumber, userId)
  );
  return data;
};

export const postCodeReview = async (
  challengeId: string,
  codeRunNumber: string,
  userId: string,
  rejectionText?: string
) => {
  await axiosTokened.post(
    API.genPostCodeRunReview(challengeId, codeRunNumber, userId),
    { rejection_text: rejectionText }
  );
};

// FIXME: remove hardocding
export const getSnapshotStatistics = async (snapshotId: string) => {
  const { data } = await axiosTokened.get<any>(
    API.genGetSnapshotStatistics(snapshotId)
  );
  return data;
};

export const getSnapshotTemplatingPacked = async (snapshotId: string) => {
  const { data } = await axiosTokened.get<SnapshotMinusTemplatePacked>(
    API.genGetPackedTemplating(snapshotId)
  );
  return data;
};

export const getSnapshotTemplatingUnpacked = async (snapshotId: string) => {
  const { data } = await axiosTokened.get<SnapshotMinusTemplateUnpacked>(
    API.genGetUnpackedTemplating(snapshotId)
  );
  return data;
};

export const getSchemaCustomization = async (snapshotId: string) => {
  const { data } = await axiosTokened.get<SchemaCustomization>(
    API.genGetSchemaCustomizationBySnapId(snapshotId)
  );
  return data;
};

export const postTemplateSaveAs = async (
  templateId: string,
  data: TemplateFork
) => {
  await axiosTokened.post(API.genPostTemplateSaveAs(templateId), data);
};

export const postEvaluateTemplatingImpact = async (
  snapshotId: string,
  labels: DataLabelsDetailed
) => {
  const { data } = await axiosTokened.post<TemplatingImpactSummary>(
    API.genPostEvaluateTemplatingImpact(snapshotId),
    labels
  );
  return data;
};
