import React from "react";
import { useQueryCurrentUserDetails } from "../../hooks/UseQueryUserDetails";
import DashboardView from "./components/DashboardView";
import { User } from "../../types/Users";
import { useParams } from "react-router-dom";
import { useQueryProjectDetails } from "./hooks/useQueryProjectDetails";


const GeneralDashboardView: React.FC = () => {
  const qUser = useQueryCurrentUserDetails();
  const user = qUser.data || ({} as User);
  const { projectId } = useParams<{ projectId: string }>();
  const qProject = useQueryProjectDetails(projectId || "noid", {
    enabled: !!projectId, // let's not silly attack backend using invalid projectId or with placeholder
  });

  const isProject = !!projectId;

  const project = qProject.data;

  return (
    <DashboardView
      title={isProject ? project?.name || "" : "All challenges dashboard"}
      user={user}
      projectId={projectId}
      isProject={isProject}
    />
  );
};

export default GeneralDashboardView;
