import React, { useState, useEffect } from "react";
import NotificationsTab from "./UserSettingsViewSteps/NotificationsTab";
import SubscriptionsTab from "./UserSettingsViewSteps/SubscriptionsTab";
import AccountTab from "./UserSettingsViewSteps/AccountTab";
import { useMutation } from "react-query";
import { Tab, Tabs } from "@mui/material";
import {
  getAllUserSubscriptions,
  updateSubscription,
  unsubscribeChallenge,
} from "./ChallengeViewSteps/LocalOperations";
import { useQueryCurrentUserDetails } from "../hooks/UseQueryUserDetails";
import {
  patchUserShownName,
  patchUserPassword,
} from "./UserSteps/LocalOperations";
import { NotificationType } from "../types/EnumNotificationType";
import PlatformRoles from "../types/PlatformRoles";
import { BackendError } from "../types/BackendError";
import { useNavigate } from "react-router-dom";
import extractError from "../utils/ErrorExtract";

const Alerts = [
  {
    key: NotificationType.NEW_TASK,
    name: "New task",
    platform: false,
    email: false,
    roles: [PlatformRoles.CONTRIBUTOR, PlatformRoles.MODERATOR],
  },
  {
    key: NotificationType.TASK_RESERVED,
    name: "Task is reserved",
    platform: false,
    email: false,
    roles: [PlatformRoles.MODERATOR],
  },
  {
    key: NotificationType.CODE_SUBMITTED,
    name: "Code submitted",
    platform: false,
    email: false,
    roles: [PlatformRoles.MODERATOR],
  },
  {
    key: NotificationType.CODE_ACCEPTED,
    name: "Code accepted",
    platform: false,
    email: false,
    roles: [PlatformRoles.CONTRIBUTOR],
  },
  {
    key: NotificationType.CODE_REJECTED,
    name: "Code rejected",
    platform: false,
    email: false,
    roles: [PlatformRoles.CONTRIBUTOR],
  },
  {
    key: NotificationType.INTERNAL_ERROR,
    name: "Internal platform errors",
    platform: false,
    email: false,
    roles: [PlatformRoles.MODERATOR],
  },
];

interface Notification {
  key: string;
  name: string;
  platform: boolean;
  email: boolean;
  roles: string[];
}

interface Subscription {
  id: number;
  alert_sent_at: string | null;
  created_at: string;
  challenge_id: number;
  notification: string;
  challenge_target_url: string;
  challenge_name: string;
}

const AccountSettingsView = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [notifications, setNotifications] = useState<Notification[]>(Alerts);
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedSubscription, setSelectedSubscription] =
    useState<Subscription | null>(null);
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const { data: user, isError, refetch } = useQueryCurrentUserDetails();
  const [newName, setNewName] = useState<string>("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const navigate = useNavigate();

  const setOnError = (error: BackendError) => {
    const errorMessage = extractError(error);
    if (errorMessage !== null) {
      setErrorMessage(errorMessage);
    }
  };

  const updateSubscriptionMutation = useMutation<
    void,
    BackendError,
    { notification: string; active: boolean }
  >((formData) => updateSubscription(formData.notification, formData.active), {
    onSuccess: () => {
      setErrorMessage("");
      setIsSuccessSnackbarOpen(true);
      setSuccessMessage("Notification updated successfully");
    },
    onError: () => {
      setErrorMessage("Failed to update notification. Please try again");
    },
  });

  const unsubscribeChallengeMutation = useMutation<void, BackendError, number>(
    (challengeId) => unsubscribeChallenge(challengeId),
    {
      onSuccess: () => {
        setErrorMessage("");
        setIsSuccessSnackbarOpen(true);
        handleCloseMenu();
      },
      onError: () => {
        setErrorMessage("Failed to unsubscribe challenge. Please try again");
      },
    }
  );

  const patchUserShownNameMutation = useMutation<any, BackendError, string>(
    patchUserShownName,
    {
      onSuccess: async () => {
        refetch();
        setIsEditingName(false);
        setErrorMessage("");
        setIsSuccessSnackbarOpen(true);
        setSuccessMessage("Name updated successfully");
      },
      onError: () => {
        setErrorMessage("Failed to update name. Please try again");
      },
    }
  );

  const patchUserPasswordMutation = useMutation<
    any,
    BackendError,
    { currentPassword: string; newPassword: string }
  >(
    async ({ currentPassword, newPassword }) => {
      await patchUserPassword(currentPassword, newPassword);
    },
    {
      onSuccess: async () => {
        refetch();
        setIsEditingName(false);
        setErrorMessage("");
        setIsSuccessSnackbarOpen(true);
        setSuccessMessage("Password updated successfully");
      },
      onError: setOnError,
    }
  );

  const handleCheckboxChange = (
    key: string,
    type: string,
    checked: boolean
  ) => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) => {
        if (notification.key === key) {
          const updatedNotification = {
            ...notification,
            [type]: !notification[type as keyof Notification],
          };

          updateSubscriptionMutation.mutate({
            notification: updatedNotification.key,
            active: checked,
          });

          return updatedNotification;
        }

        return notification;
      })
    );
  };

  const handleActionClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    subscription: Subscription
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedSubscription(subscription);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedSubscription(null);
  };

  const handleManageSubscription = (challengeId: number) => {
    navigate(`/challenges/${challengeId}?tab=alerts`);
  };

  const handleViewChallenge = (challengeId: number) => {
    navigate(`/challenges/${challengeId}?tab=challenge_description`);
  };

  const handleNameEditClick = () => {
    setIsEditingName(true);
  };

  const handlePasswordEditClick = () => {
    setIsEditingPassword(true);
  };

  const handleNameSaveClick = () => {
    if (newName) {
      patchUserShownNameMutation.mutate(newName);
    } else {
      setErrorMessage("User name cannot be empty");
    }
  };

  const handleNameCancelClick = () => {
    setIsEditingName(false);
  };

  const handlePasswordSaveClick = () => {
    if (newPassword !== confirmPassword) {
      setErrorMessage("New password and confirm password fields do not match");
      return;
    }
    if (!currentPassword || !newPassword || !confirmPassword) {
      setErrorMessage("All password fields have to be filled in");
      return;
    }
    patchUserPasswordMutation.mutate({ currentPassword, newPassword });
  };

  const handlePasswordCancelClick = () => {
    setIsEditingPassword(false);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewName(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPassword(e.target.value);
  };

  const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(e.target.value);
  };

  const handleUnsubscribe = (challengeId: number, challengeName: string) => {
    unsubscribeChallengeMutation.mutate(challengeId);
    setSubscriptions((prevSubscriptions) =>
      prevSubscriptions.filter(
        (subscription) => subscription.challenge_id !== challengeId
      )
    );
    setSuccessMessage(
      `#${challengeId} ${challengeName} unsubscribed successfully`
    );
  };

  useEffect(() => {
    if (user) {
      fetchData();
    }
  }, [user]);

  const fetchData = async () => {
    try {
      const [subscriptionsData] = await Promise.all([
        getAllUserSubscriptions(),
      ]);

      const subscriptions: Subscription[] = subscriptionsData;

      //Unique specific challenge subscriptions for subscriptions tab
      const uniqueSubscriptions = Array.from(
        new Map(
          subscriptions
            .filter((subscription) => subscription.challenge_id)
            .map((subscription) => [subscription.challenge_id, subscription])
        ).values()
      );

      //Global notification subscriptions for notifications tab
      const checkInNotifications = notifications.map((notification) => {
        const subscription = subscriptions.find(
          (sub) => sub.notification === notification.key.toLowerCase()
        );
        if (subscription) {
          return { ...notification, email: true };
        } else {
          return { ...notification, email: false };
        }
      });

      const filteredNotifications = checkInNotifications.filter(
        (notification) =>
          user && notification.roles.includes(user.role_name as string)
      );

      setSubscriptions(uniqueSubscriptions);
      setNotifications(filteredNotifications);
      setIsLoading(false);
    } catch (error) {
      setErrorMessage("Failed to fetch data");
      setIsLoading(false);
    }
  };

  return (
    <>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        aria-label="User settings tabs"
        sx={{ marginBottom: 2 }}
      >
        <Tab label="Account" />
        <Tab label="Notifications" />
        <Tab label="Subscriptions" />
      </Tabs>
      {selectedTab === 0 && (
        <AccountTab
          currentUser={user}
          isEditingName={isEditingName}
          newName={newName}
          isEditingPassword={isEditingPassword}
          currentPassword={currentPassword}
          newPassword={newPassword}
          confirmPassword={confirmPassword}
          handleNameChange={handleNameChange}
          handlePasswordChange={handlePasswordChange}
          handleNewPasswordChange={handleNewPasswordChange}
          handleConfirmPasswordChange={handleConfirmPasswordChange}
          handleNameEditClick={handleNameEditClick}
          handleNameSaveClick={handleNameSaveClick}
          handleNameCancelClick={handleNameCancelClick}
          handlePasswordEditClick={handlePasswordEditClick}
          handlePasswordSaveClick={handlePasswordSaveClick}
          handlePasswordCancelClick={handlePasswordCancelClick}
          isLoading={isLoading}
          isError={isError}
          errorMessage={errorMessage}
          isSuccessSnackbarOpen={isSuccessSnackbarOpen}
          successMessage={successMessage}
          closeSnackbar={() => setIsSuccessSnackbarOpen(false)}
        />
      )}
      {selectedTab === 1 && (
        <NotificationsTab
          notifications={notifications}
          isLoading={isLoading}
          errorMessage={errorMessage}
          handleCheckboxChange={handleCheckboxChange}
          isSuccessSnackbarOpen={isSuccessSnackbarOpen}
          successMessage={successMessage}
          closeSnackbar={() => setIsSuccessSnackbarOpen(false)}
        />
      )}
      {selectedTab === 2 && (
        <SubscriptionsTab
          subscriptions={subscriptions}
          handleActionClick={handleActionClick}
          handleCloseMenu={handleCloseMenu}
          handleManageSubscription={handleManageSubscription}
          handleUnsubscribe={handleUnsubscribe}
          handleViewChallenge={handleViewChallenge}
          isSuccessSnackbarOpen={isSuccessSnackbarOpen}
          successMessage={successMessage}
          closeSnackbar={() => setIsSuccessSnackbarOpen(false)}
          anchorEl={anchorEl}
          selectedSubscription={selectedSubscription}
          errorMessage={errorMessage}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default AccountSettingsView;
