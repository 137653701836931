import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import NoPage from "./pages/NoPage";
import ChallengesView from "./pages/ChallengesView";
import { CssBaseline } from "@mui/material";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import Finish from "./pages/ChallengesNewSteps/Finish";
import ChallengeRewards from "./pages/ChallengeViewSteps/ChallengeReward";
import ChallengeFormat from "./pages/ChallengeViewSteps/ChallengeFormat";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { queryClient } from "./utils/QueryClient";
import { UserStateProvider } from "./hooks/UserStateProvider";
import PageChallenges from "./PageChallenges";
import PageDatasets from "./PageDatasets";
import PageLogin from "./PageLogin";
import PageChallengeNew from "./PageChallengeNew";
import UsersView from "./pages/UsersView";
import UserDetailsView from "./pages/UserDetailsView";
import UserSubscriptionsView from "./pages/UserSubscriptionsView";
import PageChallengeDetails from "./PageChallengeDetails";
import AccountSettingsView from "./pages/AccountSettingsView";
import PageCodeRuns from "./PageCodeRuns";
import PageUserNew from "./PageUserNew";
import PageDashboard from "./PageDashboard";
import BaseNav from "./BaseNav";
import RedirectToSubmitCode from "./utils/RedirectToSubmitCodeTab";
import PageHome from "./PageHome";

const googleClientId = process.env.REACT_APP_GOOGLE_OAUTH2_CLIENT_ID;

function App() {
  return (
    <>
      <CssBaseline />
      <GoogleOAuthProvider clientId={googleClientId ?? ""}>
        <UserStateProvider isRedirectOnAnonymous>
          <QueryClientProvider client={queryClient}>
            <BaseNav>
              <Routes>
                <Route path="jobs" element={<Home />} />
                <Route path="/">
                  <Route index element={<PageHome />} />
                </Route>
                <Route path="dashboard">
                  <Route index element={<PageDashboard />} />
                </Route>
                <Route path="datasets">
                  <Route index element={<PageDatasets />} />
                </Route>
                <Route path="projects">
                  <Route path=":projectId" element={<PageDashboard />} />
                  <Route path="challenges" element={<PageChallenges />} />
                </Route>
                <Route path="challenges">
                  <Route
                    index
                    element={<ChallengesView viewReserved={false} />}
                  />
                  <Route path=":challengeId">
                    <Route index element={<PageChallengeDetails />} />
                    <Route path="submission">
                      <Route
                        path="from/:runNumber/user/:userId"
                        element={<RedirectToSubmitCode />}
                      />
                    </Route>
                    <Route path="reward" element={<ChallengeRewards />} />
                    <Route
                      path="code/all/user/:userId"
                      element={<PageCodeRuns isViewingSingleCodeRun={false} />}
                    />
                    <Route
                      path="code/:runNumber/user/:userId"
                      element={<PageCodeRuns isViewingSingleCodeRun={true} />}
                    />
                    <Route path="format" element={<ChallengeFormat />} />
                  </Route>
                  <Route path="new">
                    <Route index element={<PageChallengeNew />} />
                    <Route path="finish" element={<Finish />} />
                  </Route>
                </Route>
                <Route path="users/new" element={<PageUserNew />} />
                <Route path="login" element={<PageLogin />} />
                <Route path="/users" element={<UsersView />} />
                <Route
                  path="/users/user_details/:userId"
                  element={<UserDetailsView />}
                />
                <Route
                  path="/my_subscriptions"
                  element={<UserSubscriptionsView />}
                />
                <Route path="/my_account" element={<AccountSettingsView />} />
                <Route path="*" element={<NoPage />} />
              </Routes>
            </BaseNav>
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          </QueryClientProvider>
        </UserStateProvider>
      </GoogleOAuthProvider>
    </>
  );
}

export default App;
