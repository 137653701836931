import {
  Checkbox,
  MenuItem,
  Select,
  Typography,
  TableRow,
  TableCell,
  FormHelperText,
  SxProps,
  Theme,
} from "@mui/material";
import DataType, {
  DataTypeAllVals,
  DataTypeBackend,
  DataTypeToBackendArg,
} from "../types/EnumDataType";
import React from "react";
import { TooltipedLabel } from "./TooltipedLabel";
import { Constraints } from "../types/DataLabelsDetailed";

interface DataTypeConfigExpandedProps {
  dataType?: DataTypeBackend;
  advisedDataType?: DataTypeBackend;
  allowAdvancedType?: boolean;
  allowEditConstraints?: boolean;
  label: string;
  optional?: boolean;
  nullable?: boolean;
  advisedNull?: boolean;
  advisedNotNull?: boolean;
  advisedOptional?: boolean;
  advisedNotOptional?: boolean;
  onOptionalChange?: (newValue: boolean) => void;
  onNullableChange?: (newValue: boolean) => void;
  onDataTypeChange?: (newValue: DataTypeBackend) => void;
  onConstraintsChange?: (newValue: Constraints) => void;
  disabled?: boolean;
  styleRow?: React.CSSProperties;
  wrapInRow?: boolean;
  cellNullClassName?: string;
  cellOptionalClassName?: string;
  cellDataTypeClassName?: string;
  cellLabelClassName?: string;
  constraints?: Constraints;
}

const dataTypesMenuItems = Object.values(DataType).map((val) => (
  <MenuItem value={DataTypeToBackendArg[val]} key={val}>
    {val}
  </MenuItem>
));

const dataTypesAllMenuItems = Object.values(DataTypeAllVals).map((val) => (
  <MenuItem value={DataTypeToBackendArg[val]} key={val}>
    {val}
  </MenuItem>
));

/** style object that is enough width to fit max-length datatype from all types (advanced included)  */
const styleWidthSelect = {
  width: `${
    Object.values(DataTypeAllVals).reduce(
      (acc, displayName) => Math.max(acc, displayName.length),
      0
    ) + 7 // at <= 5 Select transforms "object" to "obje..."
  }ch`, // 1ch = 1 character width
};

const creatableDataTypes = new Set<string>(Object.keys(DataType));
const creatableDataTypesAll = new Set<string>(Object.keys(DataTypeAllVals));

const DataTypeConfigExpanded = ({
  wrapInRow = true,
  cellNullClassName,
  cellOptionalClassName,
  cellDataTypeClassName,
  cellLabelClassName,
  constraints,
  ...props
}: DataTypeConfigExpandedProps) => {
  const _menuItems = props.allowAdvancedType
    ? dataTypesAllMenuItems
    : dataTypesMenuItems;
  const _creatableDataTypes = props.allowAdvancedType
    ? creatableDataTypesAll
    : creatableDataTypes;
  const sxPleaseCheck: SxProps<Theme> = { color: "success.main" };
  const sxPleaseUncheck: SxProps<Theme> = {
    "&.Mui-checked": { color: "error.main" },
    "&.Mui-checked:not(.Mui-disabled)": { color: "error.main" },
  };
  const Wrapper = wrapInRow
    ? ({ children }: { children?: React.ReactNode }) => (
        <TableRow style={props.styleRow}>{children}</TableRow>
      )
    : React.Fragment;

  const _dataTypeTexted = props.dataType ?? "Not defined";

  return (
    <Wrapper>
      <TableCell className={cellLabelClassName}>
        <TooltipedLabel
          label={props.label}
          constraints={constraints}
          onConstraintsChange={props.onConstraintsChange}
          disabledEdit={props.disabled || !props.allowEditConstraints}
        />
      </TableCell>
      <TableCell className={cellDataTypeClassName}>
        {props.disabled ? (
          <Typography style={{ color: "silver" }}>{_dataTypeTexted}</Typography>
        ) : _creatableDataTypes.has(props.dataType ?? "") || !props.dataType ? (
          <Select
            labelId={`select-label-${props.label}`}
            id={`datatype-select-${props.label}`}
            value={props.dataType ?? ""}
            onChange={(e) =>
              props.onDataTypeChange?.(e.target.value as DataTypeBackend)
            }
            disabled={props.disabled}
            size="small" // height
            style={styleWidthSelect}
          >
            {_menuItems}
          </Select>
        ) : (
          <Typography
            style={{
              border: "1.5px solid silver",
              borderRadius: "4px",
              padding: "10px 8px",
              textTransform: "uppercase",
              color: "silver",
            }}
          >
            {_dataTypeTexted}
          </Typography>
        )}
        {props.advisedDataType && props.dataType !== props.advisedDataType ? (
          <FormHelperText>
            advised:{" "}
            {DataTypeAllVals[props.advisedDataType] || props.advisedDataType}
          </FormHelperText>
        ) : null}
        {!props.disabled && !props.dataType && (
          <FormHelperText>will fallback to {DataType.str}</FormHelperText>
        )}
      </TableCell>
      <TableCell className={cellOptionalClassName}>
        <Checkbox
          checked={props.optional}
          onChange={(e) => props.onOptionalChange?.(e.target.checked)}
          name="optional"
          disabled={props.disabled}
          sx={
            props.advisedOptional && !props.optional
              ? sxPleaseCheck
              : props.advisedNotOptional && props.optional
              ? sxPleaseUncheck
              : {}
          }
        />
      </TableCell>
      <TableCell className={cellNullClassName}>
        <Checkbox
          checked={props.nullable}
          onChange={(e) => props.onNullableChange?.(e.target.checked)}
          name="nullable"
          disabled={props.disabled}
          sx={
            props.advisedNull && !props.nullable
              ? sxPleaseCheck
              : props.advisedNotNull && props.nullable
              ? sxPleaseUncheck
              : {}
          }
        />
      </TableCell>
    </Wrapper>
  );
};

export default DataTypeConfigExpanded;
