import {
  Typography,
  Grid,
  Button,
  TextField,
  Checkbox,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { Link } from "react-router-dom";
import PasswordInput from "../LoginSteps/components/PasswordInput";

interface NewAccountDetailsProps {
  onAccountCreationSuccess: (email: string, password: string) => void;
  email: string;
  password: string;
  didAgree: boolean;
  setUserEmail: (email: string) => void;
  setUserPassword: (password: string) => void;
  setAgreed: (agreed: boolean) => void;
  isCreatingUser: boolean;
}

const DefineAccountDetails = ({
  onAccountCreationSuccess,
  email,
  password,
  didAgree,
  setUserEmail,
  setUserPassword,
  setAgreed,
  isCreatingUser,
}: NewAccountDetailsProps) => {
  const onEmailChange = (newEmail: string) => {
    setUserEmail(newEmail);
  };

  const onPasswordChange = (newPassword: string) => {
    setUserPassword(newPassword);
  };

  const getButtonTooltip = () => {
    if (!email) {
      return "Please provide user email to continue";
    } else if (!password) {
      return "Please provide user password to continue";
    } else if (!didAgree) {
      return "You must agree with terms and conditions to continue";
    } else {
      return "";
    }
  };

  return (
    <Grid
      container
      className="containerMain"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12} marginBottom={2} marginTop={2}>
        <Typography variant="h4" gutterBottom>
          Sign up to get data
        </Typography>
      </Grid>
      <Grid container justifyContent={"center"} marginBottom={1}>
        <Grid item xs={3.25}>
          <TextField
            placeholder="Email address"
            fullWidth
            value={email}
            onChange={(e) => onEmailChange(e.target.value)}
            className="text-field-medium"
          />
        </Grid>
      </Grid>
      <Grid container justifyContent={"center"}>
        <Grid item xs={3.25}>
          <PasswordInput
            placeholder="Password"
            value={password}
            onChange={(e) => onPasswordChange(e.target.value)}
            className="text-field-medium"
          />
        </Grid>
      </Grid>

      <Grid container alignItems="center" justifyContent={"center"} spacing={1}>
        <Grid item marginBottom={2}>
          <Checkbox
            checked={didAgree}
            onChange={(event) => setAgreed(event.target.checked)}
            color="primary"
          />
        </Grid>
        <Grid item xs={3} marginTop={3}>
          <Typography variant="body2" align="left">
            Yes, I understand and agree to the{" "}
            <Link to="/terms" style={{ textDecoration: "none" }}>
              <Typography variant="body2" color="primary" component="span">
                DataPlatform Terms of Service
              </Typography>
            </Link>{" "}
            and{" "}
            <Link to="/privacy" style={{ textDecoration: "none" }}>
              <Typography variant="body2" color="primary" component="span">
                Privacy Policy
              </Typography>
            </Link>
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12} marginTop={3}>
        <Tooltip title={getButtonTooltip()}>
          <span>
            <Button
              variant="contained"
              size="large"
              onClick={() => onAccountCreationSuccess(email, password)}
              disabled={!email || !password || !didAgree || isCreatingUser}
              endIcon={isCreatingUser ? <CircularProgress size={24} /> : null}
            >
              {isCreatingUser ? "Creating account..." : "Create my account"}
            </Button>
          </span>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default DefineAccountDetails;
