import {
  QueryKey,
  UseInfiniteQueryOptions,
  useInfiniteQuery,
  useQueryClient,
} from "react-query";
import { ChallengesBrief } from "../../../types/ChallengesBrief";
import { BackendError } from "../../../types/BackendError";
import { getChallengesBriefFiltered } from "../LocalOperations";
import { useEffect, useCallback } from "react";
import {
  BackendFilterTypes,
  ChallengeFilter,
} from "../../ChallengeViewSteps/hooks/UseChallengeFilter";
import { CHALLENGES_PER_PAGE } from "../../../Const";

interface UseInfQueryChallengesProps {
  isNextPageTriggered: boolean;
  filters: ChallengeFilter[];
  options?: Omit<
    UseInfiniteQueryOptions<
      ChallengesBrief,
      BackendError,
      ChallengesBrief,
      ChallengesBrief,
      QueryKey
    >,
    "queryKey" | "queryFn"
  >;
}

export const useInfQueryChallenges = ({
  isNextPageTriggered,
  filters,
  options,
}: UseInfQueryChallengesProps) => {
  const backendArgs = BackendFilterTypes.map(
    (type0) => filters.find(({ type }) => type === type0)?.value
  );
  const infQuery = useInfiniteQuery<ChallengesBrief, BackendError>(
    ["challenges", ...backendArgs],
    (pageObjParam) => getChallengesBriefFiltered(pageObjParam, filters),
    {
      getNextPageParam: (lastData, allData) =>
        // undefined is meant for react-query to signalize the end of pagination
        lastData.length < CHALLENGES_PER_PAGE ? undefined : allData.length,
      ...options,
    }
  );

  useEffect(() => {
    if (
      !infQuery.isFetchingNextPage &&
      infQuery.hasNextPage &&
      isNextPageTriggered
    ) {
      infQuery.fetchNextPage();
    }
  }, [isNextPageTriggered, infQuery]);

  return infQuery;
};

export const useInvalidateChallenges = (
) => {
  const queryClient = useQueryClient();
  return useCallback(
    () => queryClient.invalidateQueries(["challenges"]),
    [queryClient]
  );
};
